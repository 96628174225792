import { Component } from '@angular/core';
import { DataService } from '../../services/data.service';
import { navItems } from '../../_nav';
import * as moment from 'moment';
import { IMediaData, IMacroData, IModelEstimation, IForecast } from '../../interfaces/dashboard';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent {

  constructor(private _interactionService: DataService) { }

  public sidebarMinimized = false;
  public navItems = navItems;

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  public countries: Array<any> = [
    {
      name: 'Slovenia',
      code: 'SLO'
    },
    {
      name: 'Croatia',
      code: 'CRO'
    },
    {
      name: 'Bosnia and Herzegovina',
      code: 'BiH'
    },
    {
      name: 'Serbia',
      code: 'SRB'
    },
    {
      name: 'Montenegro',
      code: 'MNE'
    },
    {
      name: 'Macedonia',
      code: 'MKD'
    }];

  public country = { name: '', code: '' };
  public month = this.getMonth(0);
  public year = this.month.split(" ")[1];
  public monthNumber = this.convertMonth(this.month.split(" ")[0]);
  public checked = undefined;

  public async selectCountry(country) {
    this.country = country;
    this.checked = undefined;
    await this.showMacroData();
    const tmp = [];
    this.latestMacroData.forEach(e => {
      tmp.push(e);
    })
    const tmpMacroDataByCountry = [];
    this.macroDataByCountry.forEach(e => {
      tmpMacroDataByCountry.push(e);
    })

    localStorage.setItem('countryCode', country.code)
    localStorage.setItem('countryName', country.name)

    this._interactionService.sendData({
      month: this.monthNumber,
      year: this.year,
      countryName: this.country.name,
      countryCode: this.country.code,
      latestMacroData: tmp,
      macroData: tmpMacroDataByCountry
    });
  }

  public countriesDropdown(countryName: string) {
    return this.countries.filter(value => value.name !== countryName);
  }

  public getMonth(difference: number) {
    const differenceTmp = new Date();
    differenceTmp.setDate(1);
    differenceTmp.setMonth(differenceTmp.getMonth() - difference);
    const month = differenceTmp.getMonth();
    const year = differenceTmp.getFullYear();

    return this._interactionService.getMonths()[month] + " " + year;
  }
  public mainChartLabels = [];
  public forecastLabels = [];
  public forecastPoints = [];
  public low80Data = [];
  public high80Data = [];
  public low95Data = [];
  public high95Data = [];


  public async selectMonth(month: number) {
    let oldChecked = this.checked;
    this.checked = month;

    this.month = this.getMonth(month);
    this.monthNumber = this.convertMonth(this.month.split(" ")[0]);

    this.year = this.month.split(" ")[1];

    await this.showMacroData();
    //await this.forecast();

    const tmp = [];
    this.latestMacroData.forEach(e => {
      tmp.push(e);
    })
    const tmpMacroDataByCountry = [];
    this.macroDataByCountry.forEach(e => {
      tmpMacroDataByCountry.push(e);
    })
    if (month === 0 && oldChecked !== month) {
      await this.forecast();

      this._interactionService.sendData({
        month: this.monthNumber,
        year: this.year,
        countryName: this.country.name,
        countryCode: this.country.code,
        latestMacroData: tmp,
        macroData: tmpMacroDataByCountry,
        forecast: this.forecastPoints,
        forecastLabels: this.forecastLabels,
        mainChartLabels: this.mainChartLabels,
        low80Data: this.low80Data,
        high80Data: this.high80Data,
        low95Data: this.low95Data,
        high95Data: this.high95Data
      });
      return;
    }

    if (oldChecked === month) {
      this.month = this.getMonth(0);
      this.monthNumber = this.convertMonth(this.month.split(" ")[0]);

      this.year = this.month.split(" ")[1];

      this.checked = undefined;
      this._interactionService.sendData({
        month: this.monthNumber,//moment().month(),
        year: this.year,//String(moment().year()),
        countryName: this.country.name,
        countryCode: this.country.code,
        latestMacroData: tmp,
        macroData: tmpMacroDataByCountry,
        forecast: [],
        forecastLabels: [],
        mainChartLabels: this.mainChartLabels,
        low80Data: [],
        high80Data: [],
        low95Data: [],
        high95Data: []
      });
      return;
    }

    this._interactionService.sendData({
      month: this.monthNumber,
      year: this.year,
      countryName: this.country.name,
      countryCode: this.country.code,
      latestMacroData: tmp,
      macroData: tmpMacroDataByCountry,
      forecast: [],
      forecastLabels: [],
      mainChartLabels: this.mainChartLabels,
      low80Data: this.low80Data,
      high80Data: this.high80Data,
      low95Data: this.low95Data,
      high95Data: this.high95Data
    });
  }

  public convertMonth(month: string) {
    return Number(this._interactionService.getMonths().indexOf(month.split(" ")[0]));
  }

  public macroData = [];
  public macroDataByCountry = []
  public macroDataIndicators = [];
  public latestMacroData = new Map();

  public async showMacroData() {
    return new Promise((resolve, reject) => {
      // Podatki od 2019-01-01 do danes
      const allDates = this._interactionService.monthsBetweenDates('2019-01-01', moment().format('YYYY-MM') + '-01');

      this._interactionService.macroDataBetweenDates(allDates).subscribe({
        next: data => {
          const macroData: IMacroData[][] = data as IMacroData[][];
          this.macroData = macroData;

          // Najdi vsa unikatna imena indikatorjev v macroData
          this.macroData.forEach(array => {
            array.forEach(element => {
              if (!this.macroDataIndicators.includes(element.Indicator)) {
                this.macroDataIndicators.push(element.Indicator);
              }
            });
            //const uniqueIndicators = [...new Set(array.map(item => item.Indicator))];
          })
          // Vrni prvi ne prazen array (najnovejši podatki)
          //const latestData = this.macroData.reverse().find(item => {
          //  return item.length > 0
          //})

          // Prva pojavitev (najnovejši podatki)
          this.latestMacroData.clear();
          this.macroDataByCountry = [];
          this.macroData.reverse().find(array => {
            if (array.length < 1) return false;
            array.forEach(element => {
              if (element.Country === this.country.code) {
                this.macroDataByCountry.push(element);
                if ('Value' in element) {
                  if (!this.latestMacroData.get(element.Indicator)) {
                    this.latestMacroData.set(element.Indicator, element);
                  }
                }
              }
            });
          })
          resolve(undefined);
        },
        error: error => {
          reject(error);
        }
      });
    })
  }

  public async forecast() {
    return new Promise((resolve, reject) => {
      this._interactionService.forecast(this.country.code).subscribe({
        next: data => {
          const forecast: IForecast[] = data as IForecast[];
          const lastDate = forecast[forecast.length - 1].Date;
          const latestDate = forecast[0].Date;
          const newDate = moment(latestDate).subtract(1, 'month').format('YYYY-MM');

          const dates = this._interactionService.monthsBetweenDates('2021-01-01', lastDate);
          const mainChartDates = this._interactionService.monthsBetweenDates('2021-01-01', String(newDate) + '-01');

          const overallAssesmentLabels = dates.map(value =>
            moment(value, 'YYYY-MM-DD').format('MMM-YYYY')
          )

          const overallAssesmentLabels2 = mainChartDates.map(value =>
            moment(value, 'YYYY-MM-DD').format('MMM-YYYY')
          )
          this.forecastLabels = overallAssesmentLabels;
          this.mainChartLabels = overallAssesmentLabels2;

          this.forecastPoints = forecast.map(element =>
            element.Point_Forecast);

          this.low80Data = forecast.map(element =>
            element.Lo_80);

          this.high80Data = forecast.map(element =>
            element.Hi_80);

          this.low95Data = forecast.map(element =>
            element.Lo_95);

          this.high95Data = forecast.map(element =>
            element.Hi_95);

          resolve(undefined);
        },
        error: error => {
          reject(error);
        }

      })
    })
  }
  async ngOnInit(): Promise<void> {
    await this.showMacroData();
    if (localStorage.getItem('countryCode') === "undefined" || this.country === undefined) {
      this.country.code = JSON.stringify(localStorage.setItem('countryCode', 'SLO'));
      this.country.name = JSON.stringify(localStorage.setItem('countryName', 'Slovenia'));
      localStorage.setItem('countryCode', this.countries[0].code)
      localStorage.setItem('countryName', this.countries[0].name)
      this.selectCountry(this.country.code);
    }
    this.country.code = (localStorage.getItem('countryCode'))
    this.country.name = (localStorage.getItem('countryName'))
    this.selectCountry(this.country);
  }
}
