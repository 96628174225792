import { Component, OnInit, ViewChild, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { DataService } from '../../services/data.service';
import { IMediaData, IModelEstimation } from '../../interfaces/dashboard';
import { BaseChartDirective } from 'ng2-charts';
import { AppComponent } from '../../app.component';
import * as moment from 'moment';
import { Observable, Subject, forkJoin } from 'rxjs';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {

  constructor(public _dataService: DataService,
    public appComponent: AppComponent) { }

  public data = [];
  public overallAssesmentValues = [];

  async ngOnInit(): Promise<void> {
    await this.getData();
  }

  async getData(): Promise<void> {
    return new Promise((resolve, reject) => {
      this._dataService.modelEstimation(this.appComponent.getDate(1)).subscribe({
        next: (modelEstimationData: IModelEstimation[]) => {
          const data: IModelEstimation[] = modelEstimationData;
          this.data = data.map(e =>
            e).filter(element =>
              element.Category === 'Overall_assessment')
          this.data.map(e => {
            const item = { 'code': e.Country, 'value': e.Model_estimation }
            this.overallAssesmentValues.push(item)
          })
          resolve(undefined);
        },
        error: error => {
          reject(error);
        }
      })
    })
  }
}
