import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

interface MailChimpResponse {
  result: string;
  msg: string;
}

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  form: FormGroup;

  demoForm = this.fb.group({
    email: ['', Validators.required],
    company: ['', Validators.required],
    message: ['', Validators.required]
  })

  demoSubmitted = false;
  success = false;
  demoError = false;


  mailChimpEndpoint = 'https://isr.us14.list-manage.com/subscribe/post-json?u=f4b30f302b5e998ab786344d0&amp;id=06a9fe650e&';
  submitted = false;
  error = '';

  constructor(private http: HttpClient,
    private formBuilder: FormBuilder, private fb: FormBuilder) { }

  emailControl = new FormControl('', [
    Validators.required,
    Validators.email
  ]);

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      emailControl: new FormControl('', [
        Validators.required,
        Validators.email,
      ])
    });
  }

  onSubmit() {

    if (this.form.invalid) return;

    this.error = '';


			const params = new HttpParams()
				.set('EMAIL', this.form.value.emailControl)
				// .set('b_f4b30f302b5e998ab786344d0', ''); // hidden input name

			const mailChimpUrl = this.mailChimpEndpoint + params.toString();

      // 'c' refers to the jsonp callback param key. This is specific to Mailchimp
			this.http.jsonp<MailChimpResponse>(mailChimpUrl, 'c').subscribe(response => {
        console.log(response);
				if (response.result && response.result !== 'error') {
					this.submitted = true;
				}
				else {
					this.error = response.msg;
				}
			}, error => {
				console.error(error);
				this.error = 'Sorry, an error occurred.';
			});

  }

  onSubmitDemoForm() {
    this.demoSubmitted = true;
    this.demoError = false;
    if (this.demoForm.invalid) return;

    return this.http
    .post(
      'https://eam.backend.dev.mortar.tovarnaidej.com/api/v1/sendmail',
      this.demoForm.value
    )
    .pipe(
      catchError(err => {
        console.log(err);
        this.demoError = true;
        return of(null);
      })
    )
    .subscribe(
      res => {
        if (res) {
          this.success = true;
          console.log(res);
        }
      }
    )
  }
}
