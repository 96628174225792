import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  constructor(public _dataService: DataService,
    public _loginService: LoginService) { }

  user = {
    user: {
      username: '',
      email: ''
    }, 
    plan: ''
  };

  public currentUser() {
    const token = localStorage.getItem('currentUser');
    this._dataService.me(token).subscribe({
      next: user => {
        this.user = user;
      },
      error: error => {
        console.log(error);
      }
    })
  }

  public logout() {
    this._loginService.logout();
  }
  
  ngOnInit(): void {
    this.currentUser();
  }
}
