import { Component, OnInit, ViewChild, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities';
import { CustomTooltips } from '@coreui/coreui-plugin-chartjs-custom-tooltips';
import { Chart } from 'chart.js';
import { DataService } from '../../services/data.service';
import { IMediaData, IModelEstimation, IAnswer } from '../../interfaces/dashboard';
import { BaseChartDirective } from 'ng2-charts';
import { AppComponent } from '../../app.component';
import * as moment from 'moment';
import { FormArray } from '@angular/forms';


@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {

  constructor(public _dataService: DataService,
    public appComponent: AppComponent) { }

  @ViewChild('myCanvas', { static: false }) canvas: ElementRef;
  @ViewChildren(BaseChartDirective) charts: QueryList<BaseChartDirective>

  public month = this.appComponent.getMonth(0);
  public year = this.month.split(" ")[1];
  public monthNumber = this.appComponent.convertMonth(this.month.split(" ")[0]);
  public selectedCountry = { name: '', code: '' };
  public dataAll = [];

  public getMonth(difference: number) {
    return this.appComponent.getMonth(difference);
  }

  public countries: Array<any> = [
    {
      name: 'Slovenia',
      code: 'SLO'
    },
    {
      name: 'Croatia',
      code: 'CRO'
    },
    {
      name: 'Bosnia and Herzegovina',
      code: 'BiH'
    },
    {
      name: 'Serbia',
      code: 'SRB'
    },
    {
      name: 'Montenegro',
      code: 'MNE'
    },
    {
      name: 'Macedonia',
      code: 'MKD'
    }];

  public countryRadarChart = this.countriesDropdown(this.selectedCountry.name)[0];

  public countryMainChart = { name: '', code: '' };

  public data = [];
  public dataRadar = [];
  public dataMedia = [];

  public checkedRadar = 0;
  public radarDate = '';
  public filteredDataRadarByDate = [];
  public categories = [];
  public dataRadarAll = [];
  public dataRadarAllComparing = [];
  public filteredDataRadarComparingByDate = [];

  public setValueRadar(time: number) {
    if (this.checkedRadar === time) {
      this.checkedRadar = undefined;
      this.charts.get(5).chart.data.datasets[0].data = [];
      this.charts.get(5).chart.data.datasets[1].data = [];
      this.charts.get(5).update();
      return;
    }

    this.checkedRadar = time;
    this.radarDate = this.getMonth(time)

    const radarDates = this._dataService.monthsBetweenDates(this.appComponent.getDate(time), moment().format('YYYY-MM') + '-01');

    this.filteredDataRadarByDate = this.dataRadarAll.flat().filter(date => moment(date.Date).isSame(moment(radarDates[0])));
    this.filteredDataRadarComparingByDate = this.dataRadarAllComparing.flat().filter(date => moment(date.Date).isSame(moment(radarDates[0])) && date.Country === this.countryRadarChart.code);

    const radarChartValues = new Array(5).fill(0);
    const radarChartValuesComparing = new Array(5).fill(0);

    this.filteredDataRadarByDate.forEach(element => {
      if (element.Category === 'Politics') {
        radarChartValues[0] = element.Model_estimation;
      }
      if (element.Category === 'Rule_of_law') {
        radarChartValues[1] = element.Model_estimation;
      }
      if (element.Category === 'Social_economic_conditions') {
        radarChartValues[2] = element.Model_estimation;
      }
      if (element.Category === 'Investment_barriers') {
        radarChartValues[3] = element.Model_estimation;
      }
      if (element.Category === 'Security_situation') {
        radarChartValues[4] = element.Model_estimation;
      }
    });

    this.charts.get(5).chart.data.datasets[0].data = radarChartValues;
    this.charts.get(5).update();

    this.filteredDataRadarComparingByDate.forEach(element => {
      if (element.Category === 'Politics') {
        radarChartValuesComparing[0] = element.Model_estimation;
      }
      if (element.Category === 'Rule_of_law') {
        radarChartValuesComparing[1] = element.Model_estimation;
      }
      if (element.Category === 'Social_economic_conditions') {
        radarChartValuesComparing[2] = element.Model_estimation;
      }
      if (element.Category === 'Investment_barriers') {
        radarChartValuesComparing[3] = element.Model_estimation;
      }
      if (element.Category === 'Security_situation') {
        radarChartValuesComparing[4] = element.Model_estimation;
      }
    });

    this.charts.get(5).chart.data.datasets[1].data = radarChartValuesComparing;
    this.charts.get(5).update();
  }

  public otherCountries = [];
  public selectCountryRadarChart(country) {
    this.countryRadarChart = country;
    this.radarChartData[1].label = country.name;
    this.charts.get(5).update();

    const radarDates = this._dataService.monthsBetweenDates(this.appComponent.getDate(this.checkedRadar), moment().format('YYYY-MM') + '-01');

    this.filteredDataRadarByDate = this.dataRadarAll.flat().filter(date => moment(date.Date).isSame(moment(radarDates[0])));
    this.filteredDataRadarComparingByDate = this.dataRadarAllComparing.flat().filter(date => moment(date.Date).isSame(moment(radarDates[0])) && date.Country === this.countryRadarChart.code);

    const radarChartValues = new Array(5).fill(0);
    const radarChartValuesComparing = new Array(5).fill(0);

    this.filteredDataRadarByDate.forEach(element => {
      if (element.Category === 'Politics') {
        radarChartValues[0] = element.Model_estimation;
      }
      if (element.Category === 'Rule_of_law') {
        radarChartValues[1] = element.Model_estimation;
      }
      if (element.Category === 'Social_economic_conditions') {
        radarChartValues[2] = element.Model_estimation;
      }
      if (element.Category === 'Investment_barriers') {
        radarChartValues[3] = element.Model_estimation;
      }
      if (element.Category === 'Security_situation') {
        radarChartValues[4] = element.Model_estimation;
      }
    });


    this.filteredDataRadarComparingByDate.forEach(element => {
      if (element.Category === 'Politics') {
        radarChartValuesComparing[0] = element.Model_estimation;
      }
      if (element.Category === 'Rule_of_law') {
        radarChartValuesComparing[1] = element.Model_estimation;
      }
      if (element.Category === 'Social_economic_conditions') {
        radarChartValuesComparing[2] = element.Model_estimation;
      }
      if (element.Category === 'Investment_barriers') {
        radarChartValuesComparing[3] = element.Model_estimation;
      }
      if (element.Category === 'Security_situation') {
        radarChartValuesComparing[4] = element.Model_estimation;
      }
    });

    this.charts.get(5).chart.data.datasets[0].data = radarChartValues;
    this.charts.get(5).chart.data.datasets[1].data = radarChartValuesComparing;
    this.charts.get(5).update();
    //this.radarChart();
  }

  public selectCountryMainChart(country) {
    if (this.checked === country.code) {
      this.checked = undefined;
      this.mainChartData[1].label = [];
      this.charts.get(4).chart.data.datasets[1].data = [];
      this.charts.get(4).update();
      return;
    }

    this.countryMainChart = country;
    this.checked = country.code;
    this.mainChartData[1].label = country.name;
    this.charts.get(4).update();

    this.modelEstimation();
  }

  public industry = 'Energy';
  public all = this._dataService.monthsBetweenDates('2019-01-01', moment().format('YYYY-MM') + '-01').length;
  public checkedBenchmark = this.all;

  public selectIndustryBenchmarkChart(industry) {
    this.industry = industry;
    this.mediaData();
  }

  public checked = undefined;
  public politics = 0.0;
  public politicsChange = 0.0;
  public securitySituation = 0.0;
  public securitySituationChange = 0.0;
  public investmentBarriers = 0.0;
  public investmentBarriersChange = 0.0;
  public socialEconomicConditions = 0.0;
  public socialEconomicConditionsChange = 0.0;
  public ruleOfLaw = 0.0;
  public ruleOfLawChange = 0.0;

  public politicsComparing = 0.0;
  public politicsChangeComparing = 0.0;
  public securitySituationComparing = 0.0;
  public securitySituationChangeComparing = 0.0;
  public investmentBarriersComparing = 0.0;
  public investmentBarriersChangeComparing = 0.0;
  public socialEconomicConditionsComparing = 0.0;
  public socialEconomicConditionsChangeComparing = 0.0;
  public ruleOfLawComparing = 0.0;
  public ruleOfLawChangeComparing = 0.0;

  public modelEstimationData = [];
  public politicsData = [];
  public securitySituationData = [];
  public investmentBarriersData = [];
  public socialEconomicConditionsData = [];
  public ruleOfLawData = [];

  // MacroData(GDP, Unemployment, ...)
  public values = new Array(4).fill(0);
  public timeStrings = new Array(4).fill('');
  public indicators = new Array(4).fill('');

  public benchmarkIndexAll = 0;

  public filteredDataMediaByDate = [];

  public setValue(time: number) {
    if (this.checkedBenchmark === time) {
      this.checkedBenchmark = undefined;
      this.charts.get(6).chart.data.datasets[0].data = [];
      this.charts.get(6).update();
      return;
    }

    this.checkedBenchmark = time;

    const mediaDates = this._dataService.monthsBetweenDates(this.appComponent.getDate(time), moment().format('YYYY-MM') + '-01');

    this.filteredDataMediaByDate = this.dataMediaByCountry.flat().filter(date => moment(date.Date).isSameOrAfter(mediaDates[0]))

    let mediaDataDates = this.filteredDataMediaByDate.map(e => e.Date);
    let formattedMediaDataDates = mediaDataDates.map(date => moment(date, 'YYYY-MM-DD').format('MMM-YYYY'));

    this.benchmarkChartLabels = [...new Set(formattedMediaDataDates)];
    this.benchmarkChartElements = this.benchmarkChartLabels.length;

    const mediaDataDate = this.filteredDataMediaByDate.filter(element => element.Date)
    this.benchmarkIndexAll = mediaDataDate.length;

    // Najdi vsa unikatna imena industrij v mediaData
    this.filteredDataMediaByDate.forEach(element => {
      if (!this.industries.includes(element.Industry)) {
        this.industries.push(element.Industry);
      }
    });

    const industry = this.filteredDataMediaByDate.filter(element =>
      element.Industry === this.industry);
    this.benchmarkChartData1 = industry.map(element => element.Value);

    this.benchmarkChartElements = industry.length;
    this.charts.get(6).chart.data.datasets[0].data = this.benchmarkChartData1;
    this.benchmarkChartData[0].label = this.industry;
    this.charts.get(6).update();
  }
  public radarChart() {
    const data: IModelEstimation[] = this.dataRadar.filter(element =>
      element.Country === this.selectedCountry.code);

    data.forEach((value, index) => {
      if (data[index].Change) {
        const dataPoliticsChange = this.dataRadar.filter(element =>
          element.Category === 'Politics').map(element =>
            element.Change);
        this.politicsChange = dataPoliticsChange[0];
        const dataSecuritySituationChange = data.filter(element =>
          element.Category === 'Security_situation').map(element =>
            element.Change);
        this.securitySituationChange = dataSecuritySituationChange[0];
        const dataInvestmentBarriersChange = data.filter(element =>
          element.Category === 'Investment_barriers').map(element =>
            element.Change);
        this.investmentBarriersChange = dataInvestmentBarriersChange[0];
        const dataSocialEconomicConditionsChange = data.filter(element =>
          element.Category === 'Social_economic_conditions').map(element =>
            element.Change);
        this.socialEconomicConditionsChange = dataSocialEconomicConditionsChange[0];
        const dataRuleOfLawChange = data.filter(element =>
          element.Category === 'Rule_of_law').map(element =>
            element.Change);
        this.ruleOfLawChange = dataRuleOfLawChange[0];

      }
    })
    // tej imena z apija v zejtde ka de lejko spreminjo on
    const dataPolitics = data.filter(element =>
      element.Category === 'Politics').map(element =>
        element.Model_estimation);

    this.politics = dataPolitics[0];

    const dataSecuritySituation = data.filter(element =>
      element.Category === 'Security_situation').map(element =>
        element.Model_estimation);

    this.securitySituation = dataSecuritySituation[0];

    const dataInvestmentBarriers = data.filter(element =>
      element.Category === 'Investment_barriers').map(element =>
        element.Model_estimation);

    this.investmentBarriers = dataInvestmentBarriers[0];

    const dataSocialEconomicConditions = data.filter(element =>
      element.Category === 'Social_economic_conditions').map(element =>
        element.Model_estimation);

    this.socialEconomicConditions = dataSocialEconomicConditions[0];

    const dataRuleOfLaw = data.filter(element =>
      element.Category === 'Rule_of_law').map(element =>
        element.Model_estimation);

    this.ruleOfLaw = dataRuleOfLaw[0];

    this.charts.get(5).chart.data.datasets[0].data = [this.politics, this.ruleOfLaw, this.socialEconomicConditions, this.investmentBarriers, this.securitySituation];
    this.radarChartData[0].label = this.selectedCountry.name;
    this.charts.get(5).update();

    const comparingCountry: IModelEstimation[] = this.dataRadar.filter(element =>
      element.Country === this.countryRadarChart.code);

    const dataPoliticsComparing = comparingCountry.filter(element =>
      element.Category === 'Politics').map(element =>
        element.Model_estimation);

    this.politicsComparing = dataPoliticsComparing[0];

    const dataSecuritySituationComparing = comparingCountry.filter(element =>
      element.Category === 'Security_situation').map(element =>
        element.Model_estimation);

    this.securitySituationComparing = dataSecuritySituationComparing[0];

    const dataInvestmentBarriersComparing = comparingCountry.filter(element =>
      element.Category === 'Investment_barriers').map(element =>
        element.Model_estimation);

    this.investmentBarriersComparing = dataInvestmentBarriersComparing[0];

    const dataSocialEconomicConditionsComparing = comparingCountry.filter(element =>
      element.Category === 'Social_economic_conditions').map(element =>
        element.Model_estimation);

    this.socialEconomicConditionsComparing = dataSocialEconomicConditionsComparing[0];

    const dataRuleOfLawComparing = data.filter(element =>
      element.Category === 'Rule_of_law').map(element =>
        element.Model_estimation);

    this.ruleOfLawComparing = dataRuleOfLawComparing[0];

    this.charts.get(5).chart.data.datasets[1].data = [this.politicsComparing, this.ruleOfLawComparing, this.socialEconomicConditionsComparing, this.investmentBarriersComparing, this.securitySituationComparing];
    this.radarChartData[1].label = this.countryRadarChart.name;
    this.charts.get(5).update();
  }

  public countriesDropdown(countryName: string) {
    return this.countries.filter(value => value.name !== countryName);
  }
  public industries = [];
  public industriesDropdown(industry: string) {
    return this.industries.filter(value => value !== industry);
  }

  public lineChartElements = 5;
  public lineChartData1: Array<number> = [];
  public lineChartData2: Array<number> = [];
  public lineChartData3: Array<number> = [];
  public lineChartData4: Array<number> = [];

  public lineChart2Data1: Array<any> = [
    {
      data: this.lineChartData1
      // label: 'Series A',
      //fill: true
    }
  ];
  public lineChart2Data2: Array<any> = [
    {
      data: this.lineChartData2
      // label: 'Series A',
      //fill: true
    }
  ];
  public lineChart2Data3: Array<any> = [
    {
      data: this.lineChartData3
      // label: 'Series A',
      //fill: true
    }
  ];
  public lineChart2Data4: Array<any> = [
    {
      data: this.lineChartData4
      // label: 'Series A',
      //fill: true
    }
  ];

  public findMax(array1, array2, array3, array4) {
    console.log(array1, array2, array3, array4)
    const array = [...array1, ...array2, ...array3, ...array4];
    console.log('array', array, 'max', Math.max(...array));
    if (array.length > 0) {
      return Math.max(...array);
    } return 1;
  }

  public findMin(array1, array2, array3, array4) {
    const array = [...array1, array2, array3, array4];
    console.log('min', Math.min(...array));
    if (array.length > 0) {
      return Math.min(...array);
    } return 0;
  }

  public lineChart2Labels1: Array<any> = [];
  public lineChart2Labels2: Array<any> = [];
  public lineChart2Labels3: Array<any> = [];
  public lineChart2Labels4: Array<any> = [];

  public lineChart2Options: any = {

    tooltips: {
      enabled: true,
      //custom: CustomTooltips
    },
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        gridLines: {
          color: 'transparent',
          zeroLineColor: 'transparent',
          display: false
        },
        ticks: {
          fontSize: 12,
          maxRotation: 0,
          minRotation: 0,
          padding: 10,
          autoSkip: false,
          fontFamily: "'Roboto', sans-serif",
          fontStyle: '500',
          fontColor: 'rgba(155, 165, 171, 1)'
        }
      }],
      yAxes: [{
        gridLines: {
          color: 'transparent',
          zeroLineColor: 'transparent',
          display: false
        },
        ticks: {
          display: true,
          //min: this.findMin(this.lineChartData1, this.lineChartData2, this.lineChartData3, this.lineChartData4),
          //max: this.findMax(this.lineChartData1, this.lineChartData2, this.lineChartData3, this.lineChartData4),
        }
      }],
    },
    elements: {
      line: {
        tension: 0.00001,
        borderWidth: 1,
        borderColor: 'rgba(6, 31, 44, 1)'
      },
      point: {
        radius: 4,
        hitRadius: 10,
        hoverRadius: 4,
        borderColor: 'rgba(6, 31, 44, 1)'
      },
    },
    legend: {
      display: false
    }
  };
  public lineChart2Colours: Array<any> = [
    { // grey
      backgroundColor: 'rgba(255, 255, 255, 255)',
    }
  ];
  public lineChart2Legend = false;
  public lineChart2Type = 'line';

  public radarChartData1: Array<number> = [];
  public radarChartData2: Array<number> = [];
  // radarChart
  public radarChartData: Array<any> = [
    {
      label: this.selectedCountry.name,
      data: this.radarChartData1,
      fill: true,
      pointBorderColor: '#07122E',
      //pointBackgroundColor: '#FFF'
      //pointHoverBackgroundColor: '#fff',
      //pointHoverBorderColor: '#07122E',
      //pointHoverBorderWidth: 5
    },
    {
      label: this.countryRadarChart.name,
      data: this.radarChartData2,
      fill: true,
      //pointBackgroundColor: '#FFF',
      pointBorderColor: '#07122E',
      // pointHoverBackgroundColor: '#fff',
      // pointHoverBorderColor: '#07122E',
      // pointHoverBorderWidth: 5
    }
  ];
  public radarChartLabels: Array<any> = [
    ["POLITICS"],
    ["THE RULE", "  OF LAW"],
    ["SOCIO-ECONOMIC", "    CONDITIONS"],
    ["INVESTMENT", "         IMPEDIMENTS"],
    ["SECURITY", "SITUATION"]
  ];
  public radarChartOptions: any = {
    tooltips: {
      enabled: false,
      intersect: true,
      mode: 'nearest',
      custom: CustomTooltips
    },
    responsive: true,
    maintainAspectRatio: false,

    gridLines: {
      // display: true,
    },
    scale: {
      pointLabels: {
        fontFamily: "'Roboto', sans-serif",
        fontWeight: '500',
        fontStyle: 'normal',
        fontSize: 12,
        fontColor: hexToRgba('#6A7980')
      },
      ticks: {
        display: false,
        maxTicksLimit: 5
        //beginAtZero: true
      }
    },
    elements: {
      line: {
        tension: 0.00001,
        borderWidth: 1
      },
      point: {
        radius: 0,
        hitRadius: 20,
        hoverRadius: 4,
        hoverBorderWidth: 3
      },
    },
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        padding: 28,
        boxWidth: 12,
        borderRadius: '200px',
        fontFamily: "'DM Sans', sans-serif",
        fontStyle: 'normal',
        fontWeight: '500',
        fontColor: hexToRgba('#07122E'),
        fontSize: 12,
        generateLabels: function (chart) {
          let labels = Chart.defaults.global.legend.labels.generateLabels(chart);
          labels[0].fillStyle = 'rgba(67, 126, 225, 1)';
          labels[0].strokeStyle = 'rgba(67, 126, 225, 1)';
          //labels[0].borderRadius = '2';
          labels[1].fillStyle = 'rgba(137, 67, 225, 1)';
          labels[1].strokeStyle = 'rgba(137, 67, 225, 1)';
          return labels;
        }
      }
    }
  };
  public radarChartColours: Array<any> = [
    { // blue
      backgroundColor: 'rgba(67, 126, 225, 0.8)',
      borderColor: 'transparent'
    },
    {
      backgroundColor: 'rgba(137, 67, 225, 0.6)',
      borderColor: 'transparent'
    }
  ];
  public radarChartLegend = true;
  public radarChartType = 'radar';

  // mainChart oz. overall assesment chart
  public mainChartElements = 12;
  public mainChartData1: Array<number> = [];
  public mainChartData2: Array<number> = [];

  public forecastData = [];
  public low80Data = [];
  public high80Data = [];
  public low95Data = [];
  public high95Data = [];

  public mainChartData: Array<any> = [
    {
      data: this.mainChartData1,//[2,1,3,4,5,1,2,4,5,3,4, 5, , , ],
      label: this.selectedCountry.name,

      pointBorderColor: '#07122E',
      fill: true,
    },
    {
      data: this.mainChartData2,
      label: this.countryMainChart.name,
      fill: true,
      pointBorderColor: '#07122E'
    },
    {
      data: this.forecastData,//[, , , , , ,, , , , , 1,5, 4,5,1],
      fill: false,
      label: 'Forecast',
      pointBorderColor: '#000000',
      color: '#000000',
      borderColor: '#000000',
      backgroundColor: '#000000',
      pointHoverBackgroundColor: '#FFF',
      pointHoverBorderColor: '#000000'
    },
    {
      data: this.low80Data,
      fill: '+1',
      label: 'Low 80',
      color: '#7f00ff',
      backgroundColor: '#7f00ff',
      pointBorderColor: '#7f00ff',
      borderColor: '#7f00ff',
      pointHoverBackgroundColor: '#FFF',
      pointHoverBorderColor: '#7f00ff'
    },
    {
      data: this.high80Data,
      fill: '0',
      label: 'High 80',
      color: '#7f00ff',
      backgroundColor: '#7f00ff',
      pointBorderColor: '#7f00ff',
      borderColor: '#7f00ff',
      pointHoverBackgroundColor: '#FFF',
      pointHoverBorderColor: '#7f00ff'
    },
    {
      data: this.low95Data,
      fill: '0',
      label: 'Low 95',
      color: '#CF9FFF',
      backgroundColor: '#CF9FFF',
      pointBorderColor: '#CF9FFF',
      borderColor: '#CF9FFF',
      pointHoverBackgroundColor: '#FFF',
      pointHoverBorderColor: '#CF9FFF'
    },
    {
      data: this.high95Data,
      fill: '-1',
      label: 'High 95',
      color: '#CF9FFF',
      backgroundColor: '#CF9FFF',
      pointBorderColor: '#CF9FFF',
      borderColor: '#CF9FFF',
      pointHoverBackgroundColor: '#FFF',
      pointHoverBorderColor: '#CF9FFF'
    },
    {
      data: this.politicsData,
      label: 'Politics',
      hidden: false,
      fill: false,
      pointBackgroundColor: 'transparent',
      backgroundColor: 'transparent',
      pointBorderColor: 'transparent',
      borderColor: 'transparent',
      pointHoverBackgroundColor: 'transparent',
      pointHoverBorderColor: 'transparent',
      pointRadius: 0,
      //fillColor: 'transparent',
      //strokeColor: 'transparent',
      //pointColor: 'transparent',
      //pointStrokeColor: 'transparent',
      //pointHighlightFill: 'transparent',
      //pointHighlightStroke: 'transparent',
      borderWidth: 0
    },
    {
      data: this.securitySituationData,
      label: 'Security situation',
      hidden: false,
      fill: false,
      pointBackgroundColor: 'transparent',
      backgroundColor: 'transparent',
      pointBorderColor: 'transparent',
      borderColor: 'transparent',
      pointHoverBackgroundColor: 'transparent',
      pointHoverBorderColor: 'transparent',
      pointRadius: 0,
      //fillColor: 'transparent',
      //strokeColor: 'transparent',
      //pointColor: 'transparent',
      //pointStrokeColor: 'transparent',
      //pointHighlightFill: 'transparent',
      //pointHighlightStroke: 'transparent',
      borderWidth: 0
    },
    {
      data: this.investmentBarriersData,
      label: 'Investment Barriers',
      hidden: false,
      fill: false,
      pointBackgroundColor: 'transparent',
      backgroundColor: 'transparent',
      pointBorderColor: 'transparent',
      borderColor: 'transparent',
      pointHoverBackgroundColor: 'transparent',
      pointHoverBorderColor: 'transparent',
      pointRadius: 0,
      //fillColor: 'transparent',
      //strokeColor: 'transparent',
      //pointColor: 'transparent',
      //pointStrokeColor: 'transparent',
      //pointHighlightFill: 'transparent',
      //pointHighlightStroke: 'transparent',
      borderWidth: 0
    },
    {
      data: this.socialEconomicConditionsData,
      label: 'Social Economic Conditions',
      hidden: false,
      fill: false,
      pointBackgroundColor: 'transparent',
      backgroundColor: 'transparent',
      pointBorderColor: 'transparent',
      borderColor: 'transparent',
      pointHoverBackgroundColor: 'transparent',
      pointHoverBorderColor: 'transparent',
      pointRadius: 0,
      //fillColor: 'transparent',
      //strokeColor: 'transparent',
      //pointColor: 'transparent',
      //pointStrokeColor: 'transparent',
      //pointHighlightFill: 'transparent',
      //pointHighlightStroke: 'transparent',
      borderWidth: 0
    },
    {
      data: this.ruleOfLawData,
      label: 'Rule of Law',
      hidden: false,
      fill: false,
      pointBackgroundColor: 'transparent',
      backgroundColor: 'transparent',
      pointBorderColor: 'transparent',
      borderColor: 'transparent',
      pointHoverBackgroundColor: 'transparent',
      pointHoverBorderColor: 'transparent',
      pointRadius: 0,
      //fillColor: 'transparent',
      //strokeColor: 'transparent',
      //pointColor: 'transparent',
      //pointStrokeColor: 'transparent',
      //pointHighlightFill: 'transparent',
      //pointHighlightStroke: 'transparent',
      borderWidth: 0
    }
  ];
  public mainChartLabels: Array<any> = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  public mainChartOptions: any = {
    tooltips: {
      enabled: false,
      intersect: true,
      position: 'nearest',
      mode: 'index',
      custom: function (tooltipModel) {
        let tooltipEl = window.document.getElementById('chartjs-tooltip');

        // Create element on first render
        if (!tooltipEl) {
          tooltipEl = window.document.createElement('div');
          tooltipEl.id = 'chartjs-tooltip';
          tooltipEl.innerHTML = '<div class="card tooltip-card"></div>';
          window.document.body.appendChild(tooltipEl);
        }

        // Hide if no tooltip
        if (tooltipModel.opacity === 0) {
          tooltipEl.style.opacity = '0';
          return;
        }

        // Set caret Position
        tooltipEl.classList.remove('above', 'below', 'no-transform');
        if (tooltipModel.yAlign) {
          tooltipEl.classList.add(tooltipModel.yAlign);
        } else {
          tooltipEl.classList.add('no-transform');
        }

        function getBody(bodyItem) {
          return bodyItem.lines;
        }

        // Set Text
        if (tooltipModel.body) {
          const dates = tooltipModel.title || []; // tak more zglednoti datum 22. aug 2020
          let bodyLines = tooltipModel.body.map(getBody);

          let innerHtml = '';

          if (((bodyLines.flat()[6] !== undefined && bodyLines.flat()[7] !== undefined) && (bodyLines.flat()[6].includes('Politics') || bodyLines.flat()[7].includes('Politics'))) && ((bodyLines.flat()[1].includes('Forecast')) || (bodyLines.flat()[2].includes('Forecast')))) {
            dates.forEach(function (title, i) {
              innerHtml +=
                `
            <div class="card-header tooltip-card-header">
            <div class="container remove-all-margin">
                <div class="row remove-all-margin">
                    <div class="col remove-all-margin">
                        <div class="row remove-all-margin">
                            <div class="col remove-all-margin">
                                <div class="tooltip-date">` + title + `</div>
                            </div>
                        </div>
                        <div class="row remove-all-margin">
                            <div class="col remove-all-margin">
                                <div class="tooltip-title">Overall assesment</div>
                            </div>
                        </div>
                    </div>
                    <div class="col remove-all-margin" style="margin-top: 4px !important; ">
                        <div class="bg-pink justify-content-center remove-all-margin">
                            <div class="tooltip-bg-pink-text">` + parseFloat(bodyLines.flat()[0].split(': ')[1]).toFixed(2) + `</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body remove-all-margin">
            <div class="container remove-all-margin">
                <div class="row tooltip-row">
                    <div class="col">
                        <ul class="list-group list-group-flush remove-all-margin border-0" style="border: none !important;">
                            <li class="list-group-item remove-only-padding border-0" style="border: none !important;">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-9 remove-all-margin">
                                            <div class="list-group-item-left">POLITICS</div>
                                        </div>
                                        <div class="col-3 remove-all-margin">
                                            <div class="list-group-item-right">`+ bodyLines.flat()[6].split(': ')[1] + `</div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="list-group-item remove-all-margin border-0" style="border: none !important;">
                              <hr class="tooltip-line">
                            </li>
                            <li class="list-group-item remove-only-padding border-0" style="border: none !important;">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-9 remove-all-margin">
                                            <div class="list-group-item-left">SECURITY SITUATION</div>
                                        </div>
                                        <div class="col-3 remove-all-margin">
                                            <div class="list-group-item-right">` + bodyLines.flat()[7].split(': ')[1] + `</div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="list-group-item remove-all-margin border-0" style="border: none !important;">
                              <hr class="tooltip-line">
                            </li>
                            <li class="list-group-item remove-only-padding border-0" style="border: none !important;">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-9 remove-all-margin">
                                            <div class="list-group-item-left">THE RULE OF LAW</div>
                                        </div>
                                        <div class="col-3 remove-all-margin">
                                            <div class="list-group-item-right">` + bodyLines.flat()[10].split(': ')[1] + `</div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="list-group-item remove-all-margin border-0" style="border: none !important;">
                              <hr class="tooltip-line">
                            </li>
                            <li class="list-group-item remove-only-padding border-0" style="border: none !important;">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-9 remove-all-margin">
                                            <div class="list-group-item-left">INVESTMENT IMPEDIMENTS</div>
                                        </div>
                                        <div class="col-3 remove-all-margin">
                                            <div class="list-group-item-right">` + bodyLines.flat()[8].split(': ')[1] + `</div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="list-group-item remove-all-margin border-0" style="border: none !important;">
                              <hr class="tooltip-line">
                            </li>
                            <li class="list-group-item remove-only-padding border-0" style="border: none !important;">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-9 remove-all-margin">
                                            <div class="list-group-item-left">SOCIO-ECONOMIC CONDITIONS</div>
                                        </div>
                                        <div class="col-3 remove-all-margin">
                                            <div class="list-group-item-right">` + bodyLines.flat()[9].split(': ')[1] + `</div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="list-group-item remove-only-padding border-0" style="border: none !important;">
                              <hr class="tooltip-line">
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="row tooltip-comment-row">
                    <div class="col">
                        <div class="card-body remove-all-margin">
                            <div class="tooltip-comment-title">Analyst comment</div>
                            <div class="tooltip-comment">The improvement of social-economic conditions is mostly due to the
                                new
                                minimum wage act that has been in effect since 1st January 2019 - 505 EUR.</div>
                            <div class="tooltip-comment-date">17. 7. 2021</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>      
`
            })
          };
          if (bodyLines.flat()[1].includes('Politics') || bodyLines.flat()[2].includes('Politics')) {
            dates.forEach(function (title, i) {
              innerHtml +=
                `
            <div class="card-header tooltip-card-header">
            <div class="container remove-all-margin">
                <div class="row remove-all-margin">
                    <div class="col remove-all-margin">
                        <div class="row remove-all-margin">
                            <div class="col remove-all-margin">
                                <div class="tooltip-date">` + title + `</div>
                            </div>
                        </div>
                        <div class="row remove-all-margin">
                            <div class="col remove-all-margin">
                                <div class="tooltip-title">Overall assesment</div>
                            </div>
                        </div>
                    </div>
                    <div class="col remove-all-margin" style="margin-top: 4px !important; ">
                        <div class="bg-pink justify-content-center remove-all-margin">
                            <div class="tooltip-bg-pink-text">` + parseFloat(bodyLines.flat()[0].split(': ')[1]).toFixed(2) + `</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body remove-all-margin">
            <div class="container remove-all-margin">
                <div class="row tooltip-row">
                    <div class="col">
                        <ul class="list-group list-group-flush remove-all-margin border-0" style="border: none !important;">
                            <li class="list-group-item remove-only-padding border-0" style="border: none !important;">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-9 remove-all-margin">
                                            <div class="list-group-item-left">POLITICS</div>
                                        </div>
                                        <div class="col-3 remove-all-margin">
                                            <div class="list-group-item-right">`+ bodyLines.flat()[1].split(': ')[1] + `</div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="list-group-item remove-all-margin border-0" style="border: none !important;">
                              <hr class="tooltip-line">
                            </li>
                            <li class="list-group-item remove-only-padding border-0" style="border: none !important;">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-9 remove-all-margin">
                                            <div class="list-group-item-left">SECURITY SITUATION</div>
                                        </div>
                                        <div class="col-3 remove-all-margin">
                                            <div class="list-group-item-right">` + bodyLines.flat()[2].split(': ')[1] + `</div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="list-group-item remove-all-margin border-0" style="border: none !important;">
                              <hr class="tooltip-line">
                            </li>
                            <li class="list-group-item remove-only-padding border-0" style="border: none !important;">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-9 remove-all-margin">
                                            <div class="list-group-item-left">THE RULE OF LAW</div>
                                        </div>
                                        <div class="col-3 remove-all-margin">
                                            <div class="list-group-item-right">` + bodyLines.flat()[3].split(': ')[1] + `</div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="list-group-item remove-all-margin border-0" style="border: none !important;">
                              <hr class="tooltip-line">
                            </li>
                            <li class="list-group-item remove-only-padding border-0" style="border: none !important;">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-9 remove-all-margin">
                                            <div class="list-group-item-left">INVESTMENT IMPEDIMENTS</div>
                                        </div>
                                        <div class="col-3 remove-all-margin">
                                            <div class="list-group-item-right">` + bodyLines.flat()[4].split(': ')[1] + `</div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="list-group-item remove-all-margin border-0" style="border: none !important;">
                              <hr class="tooltip-line">
                            </li>
                            <li class="list-group-item remove-only-padding border-0" style="border: none !important;">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-9 remove-all-margin">
                                            <div class="list-group-item-left">SOCIO-ECONOMIC CONDITIONS</div>
                                        </div>
                                        <div class="col-3 remove-all-margin">
                                            <div class="list-group-item-right">` + bodyLines.flat()[5].split(': ')[1] + `</div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="list-group-item remove-only-padding border-0" style="border: none !important;">
                              <hr class="tooltip-line">
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="row tooltip-comment-row">
                    <div class="col">
                        <div class="card-body remove-all-margin">
                            <div class="tooltip-comment-title">Analyst comment</div>
                            <div class="tooltip-comment">` + localStorage.getItem('comment') + `</div>
                            <div class="tooltip-comment-date">` + localStorage.getItem('commentDate') + `</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>      
`
            })
          } else {
            let data = [];
            dates.forEach(function (title) {
              innerHtml += title + '</div>';
            });
            innerHtml += '<div class="card-body">';

            bodyLines.forEach(function (body, i) {
              let colors = tooltipModel.labelColors[i];
              let style = 'background:' + colors.backgroundColor;
              style += '; border-color:' + colors.borderColor;
              style += '; border-width: 2px';
              let span = '<span style="' + style + '"></span>';

              data.push(body);
              innerHtml += '<tr><td>' + span;

            });
            innerHtml += data[0] + '<br>' + '</td></tr>'
              + 'Interval 80: [' + String(data[1]).split(': ')[1] + ', ' + String(data[2]).split(': ')[1] + ']<br>' + '</td></tr>'
              + 'Interval 95: [' + String(data[3]).split(': ')[1] + ', ' + String(data[4]).split(': ')[1] + ']<br>' + '</td></tr>'

            innerHtml += '</div>';
          }
          let tableRoot = tooltipEl.querySelector('.card');
          tableRoot.innerHTML = innerHtml;
        }

        // `this` will be the overall tooltip
        let position = this._chart.canvas.getBoundingClientRect();

        // Display, position, and set styles for font
        tooltipEl.style.opacity = '1';
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
        tooltipEl.style.pointerEvents = 'none';
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [{

        offset: false,
        gridLines: {
          drawOnChartArea: true,
          //offsetGridLines: true
          offsetGridLines: false,
          drawBorder: false,
          drawTicks: true,
          tickMarkLength: 1
          // zeroLineBorderDashOffset: 1.0
        },
        ticks: {
          callback: function (value: any) {
            return value
          },
          fontFamily: "'DM Sans', 'sans-serif",
          fontStyle: 'bold',
          fontWeight: '700',
          fontSize: 12,
          fontColor: hexToRgba('#BBBDC2'),
          lineHeight: 1.98,
          beginAtZero: false,
          autoSkip: true,
          padding: 10,
          labelOffset: -10,
          // autoSkipPadding: 0, padding: 20
        }
      }],
      layout: {
        padding: {
          left: -110,
          right: -10,
          top: 0,
          bottom: 0
        }
      },
      yAxes: [{
        gridLines: {
          drawOnChartArea: true,
          //offsetGridLines: true
          offsetGridLines: false,
          drawBorder: false,
          drawTicks: true,
          tickMarkLength: 1
          // zeroLineBorderDashOffset: 1.0
        },
        ticks: {
          beginAtZero: true,
          stepSize: 2,
          fontFamily: "'DM Sans', 'sans-serif",
          fontStyle: 'bold',
          fontWeight: '700',
          fontSize: 12,
          fontColor: hexToRgba('#BBBDC2'),
          lineHeight: 1.98,
          autoSkip: true,
          padding: 20
          // max: 10
        }
      }]
    },
    elements: {
      line: {
        borderWidth: 2,
        tension: 0.00001
      },
      point: {
        radius: 0,
        hitRadius: 20,
        hoverRadius: 4,
        hoverBorderWidth: 3,
      }
    },
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        padding: 28,
        boxWidth: 12,
        fontFamily: "'DM Sans', sans-serif",
        fontStyle: 'normal',
        fontWeight: '500',
        fontColor: hexToRgba('#07122E'),
        fontSize: 12,
        generateLabels: function (chart) {
          let labels = Chart.defaults.global.legend.labels.generateLabels(chart);
          labels[0].fillStyle = 'rgba(67, 126, 225, 1)';
          labels[0].strokeStyle = 'rgba(67, 126, 225, 1)';
          labels[1].fillStyle = 'rgba(137, 67, 225, 1)';
          labels[1].strokeStyle = 'rgba(137, 67, 225, 1)';
          return labels;
        },
        filter: function (item, chart) {
          // odstrani legend boxe
          return !item.text.includes('Forecast')
            && !item.text.includes('Politics')
            && !item.text.includes('Security situation')
            && !item.text.includes('Investment Barriers')
            && !item.text.includes('Social Economic Conditions')
            && !item.text.includes('Rule of Law')
            && !item.text.includes('Low 80')
            && !item.text.includes('High 80')
            && !item.text.includes('Low 95')
            && !item.text.includes('High 95');
        }
      }
    },
    options: {
      hover: {
        mode: 'y',
        intersect: 'false',
        axis: 'y'
      }
    }
  };

  public mainChartColours: Array<any> = [
    { // brandInfo
      borderColor: 'rgba(67, 126, 225, 0.8)',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderWidth: 5
    },
    { // brandSuccess
      borderColor: 'rgba(137, 67, 225, 0.6)',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderWidth: 5
    }
  ];
  public mainChartLegend = true;
  public mainChartType = 'line';

  // Benchmark chart
  public benchmarkChartColours: Array<any> = [
    { // brandInfo
      borderColor: 'rgba(67, 126, 225, 0.8)',
      pointHoverBackgroundColor: '#fff'
    },
    { // brandSuccess
      borderColor: 'rgba(137, 67, 225, 0.6)',
      pointHoverBackgroundColor: '#fff'
    }
  ];

  public benchmarkChartElements = 12;
  public benchmarkChartData1: Array<number> = [];
  public benchmarkChartData2: Array<number> = [];

  public benchmarkChartData: Array<any> = [
    {
      data: this.benchmarkChartData1,
      label: 'Industry',
      pointBorderColor: '#07122E',
      fill: 'start'
    }
  ];
  /* tslint:disable:max-line-length */
  public benchmarkChartLabels: Array<any> = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  /* tslint:enable:max-line-length */
  public doughnutChartPlugin = [
    {
      beforeInit: function (chart, options) {
        chart.legend.afterFit = function () {
          this.height += 50; // must use `function` and not => because of `this`
        };
      }
    }
  ];
  public benchmarkChartOptions: any = {
    tooltips: {
      enabled: true,
      intersect: true,
      mode: 'index',
      position: 'nearest',
      callbacks: {
        labelColor: function (tooltipItem, chart) {
          return { backgroundColor: chart.data.datasets[tooltipItem.datasetIndex].borderColor };
        }
      }
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        gridLines: {
          drawOnChartArea: true,
          //offsetGridLines: true
          offsetGridLines: false,
          drawBorder: false,
          drawTicks: true,
          tickMarkLength: 1
          // zeroLineBorderDashOffset: 1.0
        },
        ticks: {
          callback: function (value: any) {
            return value
          },
          fontFamily: "'DM Sans', 'sans-serif",
          fontStyle: 'bold',
          fontWeight: '700',
          fontSize: 12,
          fontColor: hexToRgba('#BBBDC2'),
          lineHeight: 1.98,
          beginAtZero: false,
          autoSkip: true,
          padding: 10
        }
      }],
      yAxes: [{
        gridLines: {
          drawOnChartArea: true,
          //offsetGridLines: true
          offsetGridLines: false,
          drawBorder: false,
          drawTicks: true,
          tickMarkLength: 1
          // zeroLineBorderDashOffset: 1.0
        },
        ticks: {
          scaleBeginAtZero: false,
          beginAtZero: false,
          scaleOverride: true,
          scaleStartValue: -10,
          stepSize: 2,
          fontFamily: "'DM Sans', 'sans-serif",
          fontStyle: 'bold',
          fontWeight: '700',
          fontSize: 12,
          fontColor: hexToRgba('#BBBDC2'),
          lineHeight: 1.98,
          autoSkip: true,
          padding: 10
        }
      }]
    },
    elements: {
      line: {
        borderWidth: 2,
        tension: 0.00001
      },
      point: {
        radius: 0,
        hitRadius: 10,
        hoverRadius: 4,
        hoverBorderWidth: 3
      }
    },
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        padding: 28,
        boxWidth: 12,
        borderRadius: '200px',
        fontFamily: "'DM Sans', sans-serif",
        fontStyle: 'normal',
        fontWeight: '500',
        fontColor: hexToRgba('#07122E'),
        fontSize: 12,
        generateLabels: function (chart) {
          let labels = Chart.defaults.global.legend.labels.generateLabels(chart);
          labels[0].fillStyle = 'rgba(67, 126, 225, 1)';
          labels[0].strokeStyle = 'rgba(67, 126, 225, 1)';
          //labels[1].fillStyle = 'rgba(137, 67, 225, 1)';
          //labels[1].strokeStyle = 'rgba(137, 67, 225, 1)';
          return labels;
        }
      }
    }
  };

  public random(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  public mainData = [];
  public modelEstimation() {
    const country = this.data.map(value =>
      value.filter(element =>
        element.Country === this.selectedCountry.code)
    );

    const overallAssessmentValue = country.map(value =>
      value.filter(element =>
        element.Category === 'Overall_assessment')[0]
    ).map(value =>
      value.Model_estimation
    );;

    const politicsValue = country.map(value =>
      value.filter(element =>
        element.Category === 'Politics')[0]
    ).map(value =>
      value.Model_estimation
    );;

    const securityValue = country.map(value =>
      value.filter(element =>
        element.Category === 'Security_situation')[0]
    ).map(value =>
      value.Model_estimation
    );

    const ruleOfLawValue = country.map(value =>
      value.filter(element =>
        element.Category === 'Rule_of_law')[0]
    ).map(value =>
      value.Model_estimation
    );

    const investmentValue = country.map(value =>
      value.filter(element =>
        element.Category === 'Investment_barriers')[0]
    ).map(value =>
      value.Model_estimation
    );;

    const socialValue = country.map(value =>
      value.filter(element =>
        element.Category === 'Social_economic_conditions')[0]
    ).map(value =>
      value.Model_estimation
    );;


    this.politicsData = politicsValue;
    this.securitySituationData = securityValue;
    this.investmentBarriersData = investmentValue;
    this.ruleOfLawData = ruleOfLawValue;
    this.investmentBarriersData = investmentValue;
    this.socialEconomicConditionsData = socialValue;

    this.mainChartElements = country.length;
    this.mainData = overallAssessmentValue;
    this.mainChartData1 = overallAssessmentValue;

    this.charts.get(4).chart.data.datasets[0].data = this.mainChartData1;
    this.charts.get(4).chart.data.datasets[7].data = politicsValue;
    this.charts.get(4).chart.data.datasets[8].data = securityValue;
    this.charts.get(4).chart.data.datasets[9].data = ruleOfLawValue;
    this.charts.get(4).chart.data.datasets[10].data = investmentValue;
    this.charts.get(4).chart.data.datasets[11].data = socialValue;

    this.mainChartData[0].label = this.selectedCountry.name;
    this.charts.get(4).update();

    if (this.countryMainChart.code === '') return;
    if (this.checked !== undefined && this.checked === this.selectedCountry.code) {
      this.checked = this.countriesDropdown(this.selectedCountry.name)[0].code;
    }
    this.mainChartData[0].label = this.selectedCountry.name;

    this.countryMainChart = this.countries.filter(element => element.code === this.checked)[0];

    this.mainChartData[1].label = this.countryMainChart.name;

    const comparingCountry = this.data.map(value =>
      value.filter(element =>
        element.Country === this.countryMainChart.code)
    );

    const comparingOverallAssessment = comparingCountry.map(value =>
      value.filter(element =>
        element.Category === 'Overall_assessment')[0]
    );

    const comparingOverallAssessmentValue = comparingOverallAssessment.map(value =>
      value.Model_estimation
    );

    if (this.forecastData.length === 0) {
      let overallAssesmentDates = comparingCountry.map(e => e.map(i => i.Date));
      let formattedOverallAssesmentDates = overallAssesmentDates.flat().map(date => moment(date, 'YYYY-MM-DD').format('MMM-YYYY'));
      formattedOverallAssesmentDates.map(element => this.mainChartLabels.push(element))

      this.mainChartLabels = [...new Set(formattedOverallAssesmentDates)];

      this.mainChartData1 = overallAssessmentValue;
      this.mainChartData2 = comparingOverallAssessmentValue;

      this.charts.get(4).chart.data.datasets[0].data = this.mainChartData1;
      this.charts.get(4).chart.data.datasets[1].data = this.mainChartData2;
      this.charts.get(4).update();

    } else {
      this.mainChartData1 = overallAssessmentValue;
      this.mainChartData2 = comparingOverallAssessmentValue;

      this.mainChartElements = this.data.length;
      const forecastDataTmp = new Array(this.dataAll.length - 1).fill(undefined);
      const low80DataTmp = new Array(this.dataAll.length - 1).fill(undefined);
      const high80DataTmp = new Array(this.dataAll.length - 1).fill(undefined);
      const low95DataTmp = new Array(this.dataAll.length - 1).fill(undefined);
      const high95DataTmp = new Array(this.dataAll.length - 1).fill(undefined);

      const mainChartDataTmp = new Array(this.forecastData.length).fill(undefined);
      const mainChartData2Tmp = new Array(this.forecastData.length).fill(undefined);
      //this.forecastData.unshift(this.mainData[this.mainData.length - 1])
      //this.low80Data.unshift(this.mainData[this.mainData.length - 1])
      //this.high80Data.unshift(this.mainData[this.mainData.length - 1])
      //this.low95Data.unshift(this.mainData[this.mainData.length - 1])
      //this.high95Data.unshift(this.mainData[this.mainData.length - 1])

      const FinalArr = [...this.mainData, ...mainChartDataTmp];
      const FinalArr2 = [...this.mainChartData2, ...mainChartData2Tmp];
      this.charts.get(4).chart.data.datasets[0].data = FinalArr;
      this.charts.get(4).chart.data.datasets[1].data = FinalArr2;
      //this.forecastData = [...forecastDataTmp, ...this.forecastData];
      //this.low80Data = [...low80DataTmp, ...this.low80Data];
      //this.high80Data = [...high80DataTmp, ...this.high80Data];
      //this.low95Data = [...low95DataTmp, ...this.low95Data];
      //this.high95Data = [...high95DataTmp, ...this.high95Data];

      //this.charts.get(4).chart.data.datasets[2].data = this.forecastData;
      //this.charts.get(4).chart.data.datasets[3].data = this.low80Data;
      //this.charts.get(4).chart.data.datasets[4].data = this.high80Data;
      //this.charts.get(4).chart.data.datasets[5].data = this.low95Data;
      //this.charts.get(4).chart.data.datasets[6].data = this.high95Data;
      //this.charts.get(4).chart.data.datasets[7].data = this.politicsData;
      //this.charts.get(4).chart.data.datasets[8].data = this.securitySituationData;
      this.charts.get(4).update();
    }
  }

  public dataMediaByCountry = [];
  public mediaData() {
    let filteredArray: IMediaData[][] = this.dataMedia.map(e => e.filter(i =>
      i.Country === this.selectedCountry.code));
    this.dataMediaByCountry = filteredArray;

    const mediaDates = this._dataService.monthsBetweenDates(this.appComponent.getDate(this.all), moment().format('YYYY-MM') + '-01');

    this.filteredDataMediaByDate = this.dataMediaByCountry.flat().filter(date => moment(date.Date).isSameOrAfter(mediaDates[0]))

    let mediaDataDates = this.filteredDataMediaByDate.map(e => e.Date);
    let formattedMediaDataDates = mediaDataDates.map(date => moment(date, 'YYYY-MM-DD').format('MMM-YYYY'));

    this.benchmarkChartLabels = [...new Set(formattedMediaDataDates)];
    this.benchmarkChartElements = this.benchmarkChartLabels.length;

    const mediaDataDate = this.filteredDataMediaByDate.filter(element => element.Date)
    this.benchmarkIndexAll = mediaDataDate.length; // to premisli slede zaj se me neda

    // Najdi vsa unikatna imena industrij v mediaData
    this.filteredDataMediaByDate.forEach(element => {
      if (!this.industries.includes(element.Industry)) {
        this.industries.push(element.Industry);
      }
    });

    const industry = this.filteredDataMediaByDate.filter(element =>
      element.Industry === this.industry);
    this.benchmarkChartData1 = industry.map(element => element.Value);

    this.benchmarkChartElements = industry.length;
    this.charts.get(6).chart.data.datasets[0].data = this.benchmarkChartData1;
    this.benchmarkChartData[0].label = this.industry;
    this.charts.get(6).update();

    //let mediaDataDates = filteredArray.map(e => e.map(i => i.Date));
    //let formattedMediaDataDates = mediaDataDates.flat().map(date => moment(date, 'YYYY-MM-DD').format('MMM-YYYY'));
    //
    //this.benchmarkChartLabels = [...new Set(formattedMediaDataDates)];
    //this.benchmarkChartElements = this.benchmarkChartLabels.length;
    //
    //const mediaDataDate = filteredArray.filter(array => array.filter(element => element.Date))
    //this.benchmarkIndexAll = mediaDataDate.length;
    //
    //// Najdi vsa unikatna imena industrij v mediaData
    //filteredArray.forEach(array => {
    //  array.forEach(element => {
    //    if (!this.industries.includes(element.Industry)) {
    //      this.industries.push(element.Industry);
    //    }
    //  });
    //});
    //
    //const industry = filteredArray.map(value =>
    //  value.filter(element =>
    //    element.Industry === this.industry)
    //);
    //
    //this.benchmarkChartData1 = industry.map(value =>
    //  value.map(element => element.Value)
    //).flat();
    //
    //this.benchmarkChartElements = industry.length;
    //this.charts.get(6).chart.data.datasets[0].data = this.benchmarkChartData1 ;
    //this.benchmarkChartData[0].label = this.industry;
    //this.charts.get(6).update();
  }

  public latestMacroData = []
  public macroData = [];
  public dates = []
  public modelEstimationDates = [];
  public forecastMonth = this.monthNumber + 1;

  public async getData() {
    return new Promise((resolve, reject) => {

      this._dataService.data$.subscribe(result => {

        const res = JSON.parse(JSON.stringify(result));
        if (res.month === moment().month()) {
          this.month = String(moment().add(30, "days").month() + 1).padStart(2, "0")//String(Number(res.month) + 1).padStart(2, "0");
        } else {
          this.month = String(Number(res.month) + 1).padStart(2, "0");
        }
        this.year = res.year;

        this.modelEstimationDates = this._dataService.monthsBetweenDates('2019-01-01', this.year + '-' + this.month + '-01');

        this.selectedCountry = {
          name: res.countryName,
          code: res.countryCode
        }

        localStorage.setItem('countryCode', this.selectedCountry.code);
        localStorage.setItem('countryName', this.selectedCountry.name);

        this.latestMacroData = res.latestMacroData;
        this.macroData = res.macroData.reverse();

        this._dataService.modelEstimationBetweenDates(this.modelEstimationDates).subscribe({
          next: (element: IModelEstimation[][]) => {
            element = element.filter(array => array.length > 0);
            this.dataAll = element;

            let filteredArray: IModelEstimation[][] = element.map(e => e.filter(i =>
              i.Country === this.selectedCountry.code &&
              i.Category === 'Overall_assessment'))

            this.dataRadarAll = element.map(e => e.filter(i =>
              i.Country === this.selectedCountry.code))

            this.dataRadarAllComparing = element.map(e => e);

            if ((res.forecast === undefined || res.forecast.length === 0) || res.low80Data === undefined || res.high80Data === undefined || res.low95Data === undefined || res.high95Data === undefined) {
              this.forecastData = [];
              this.low80Data = [];
              this.high80Data = [];
              this.low95Data = [];
              this.high95Data = [];
              //this.mainChartLabels = res.mainChartLabels;
              //this.mainChartData[2].label = [];

              this.charts.get(4).chart.data.datasets[2].data = [];
              this.charts.get(4).chart.data.datasets[3].data = [];
              this.charts.get(4).chart.data.datasets[4].data = [];
              this.charts.get(4).chart.data.datasets[5].data = [];
              this.charts.get(4).chart.data.datasets[6].data = [];
              this.charts.get(4).update();

              let overallAssesmentDates = filteredArray.map(e => e.map(i => i.Date));
              let formattedOverallAssesmentDates = overallAssesmentDates.flat().map(date => moment(date, 'YYYY-MM-DD').format('MMM-YYYY'));
              /*
              
              const mediaDates = this._dataService.monthsBetweenDates(this.appComponent.getDate(this.all), moment().format('YYYY-MM') + '-01');
            
              this.filteredDataMediaByDate = this.dataMediaByCountry.flat().filter(date => moment(date.Date).isSameOrAfter(mediaDates[0]))
              
              let mediaDataDates = this.filteredDataMediaByDate.map(e => e.Date);
              let formattedMediaDataDates = mediaDataDates.map(date => moment(date, 'YYYY-MM-DD').format('MMM-YYYY'));
              
              this.mainChartLabels = [...new Set(formattedMediaDataDates)];
              this.mainChartElements = this.benchmarkChartLabels.length;
              */
              this.mainChartLabels = formattedOverallAssesmentDates
              this.data = element;
              this.modelEstimation();

            } else {
              this.mainChartLabels = res.forecastLabels;
              this.data = element;
              this.forecastData = res.forecast;
              this.low80Data = res.low80Data;
              this.high80Data = res.high80Data;
              this.low95Data = res.low95Data;
              this.high95Data = res.high95Data;

              this.modelEstimation();

              this.mainChartElements = this.data.length;
              const forecastDataTmp = new Array(this.dataAll.length - 1).fill(undefined);
              const low80DataTmp = new Array(this.dataAll.length - 1).fill(undefined);
              const high80DataTmp = new Array(this.dataAll.length - 1).fill(undefined);
              const low95DataTmp = new Array(this.dataAll.length - 1).fill(undefined);
              const high95DataTmp = new Array(this.dataAll.length - 1).fill(undefined);

              const mainChartDataTmp = new Array(this.forecastData.length).fill(undefined);
              this.forecastData.unshift(this.mainData[this.mainData.length - 1])
              this.low80Data.unshift(this.mainData[this.mainData.length - 1])
              this.high80Data.unshift(this.mainData[this.mainData.length - 1])
              this.low95Data.unshift(this.mainData[this.mainData.length - 1])
              this.high95Data.unshift(this.mainData[this.mainData.length - 1])

              const FinalArr = [...this.mainData, ...mainChartDataTmp];
              this.charts.get(4).chart.data.datasets[0].data = FinalArr;

              this.forecastData = [...forecastDataTmp, ...this.forecastData];
              this.low80Data = [...low80DataTmp, ...this.low80Data];
              this.high80Data = [...high80DataTmp, ...this.high80Data];
              this.low95Data = [...low95DataTmp, ...this.low95Data];
              this.high95Data = [...high95DataTmp, ...this.high95Data];

              this.charts.get(4).chart.data.datasets[2].data = this.forecastData;
              this.charts.get(4).chart.data.datasets[3].data = this.low80Data;
              this.charts.get(4).chart.data.datasets[4].data = this.high80Data;
              this.charts.get(4).chart.data.datasets[5].data = this.low95Data;
              this.charts.get(4).chart.data.datasets[6].data = this.high95Data;
              this.charts.get(4).chart.data.datasets[7].data = this.politicsData;
              this.charts.get(4).chart.data.datasets[8].data = this.securitySituationData;
              this.charts.get(4).update();
            }
          },
          error: error => {
            console.error(error);
          }
        });

        new Array(12).fill(undefined).map((value, i) => {
          if (this.latestMacroData[i]) {
            this.values[i] = this.latestMacroData[i].Value;
            this.timeStrings[i] = this.latestMacroData[i].Time_str;
            this.indicators[i] = this.latestMacroData[i].Indicator;

          } else {
            this.values[i] = undefined;
            this.timeStrings[i] = 'No data';
          }
        });

        this.lineChart2Labels1 = [];
        this.lineChart2Labels2 = [];
        this.lineChart2Labels3 = [];
        this.lineChart2Labels4 = [];
        this.lineChartData1.length = 0;
        this.lineChartData2.length = 0;
        this.lineChartData3.length = 0;
        this.lineChartData4.length = 0;

        this.macroData.find(element => {
          if (element.Indicator === this.indicators[0]) {
            this.lineChart2Labels1.push(element.Date);
            this.lineChartData1.push(element.Value);
          }
          if (element.Indicator === this.indicators[1]) {
            this.lineChart2Labels2.push((element.Date));
            this.lineChartData2.push(element.Value);
          }
          if (element.Indicator === this.indicators[2]) {
            this.lineChart2Labels3.push((element.Date));
            this.lineChartData3.push(element.Value);
          }
          if (element.Indicator === this.indicators[3]) {
            this.lineChart2Labels4.push((element.Date));
            this.lineChartData4.push(element.Value);
          }
        })
        // Pokažemo vse januarje in prvi in zadnji datum 
        this.lineChart2Labels1 = this.lineChart2Labels1.map((element, index) => {
          if (moment(element).month() != 0 && index !== 0 && index !== this.lineChart2Labels1.length - 1) {
            return '';
          }
          return moment(element).format('YYYY-MM');
        })
        this.lineChart2Labels1 = this.lineChart2Labels1.filter(label => label != '');
        this.lineChart2Labels2 = this.lineChart2Labels2.map((element, index) => {
          if (moment(element).month() != 0 && index !== 0 && index !== this.lineChart2Labels2.length - 1) {
            return '';
          }
          return moment(element).format('YYYY-MM');
        })
        this.lineChart2Labels2 = this.lineChart2Labels2.filter(label => label != '');
        this.lineChart2Labels3 = this.lineChart2Labels3.map((element, index) => {
          if (moment(element).month() != 0 && index !== 0 && index !== this.lineChart2Labels3.length - 1) {
            return '';
          }
          return moment(element).format('YYYY-MM');
        })
        this.lineChart2Labels3 = this.lineChart2Labels3.filter(label => label != '');
        this.lineChart2Labels4 = this.lineChart2Labels4.map((element, index) => {
          if (moment(element).month() != 0 && index !== 0 && index !== this.lineChart2Labels4.length - 1) {
            return '';
          }
          return moment(element).format('YYYY-MM');
        })
        this.lineChart2Labels4 = this.lineChart2Labels4.filter(label => label != '');

        this.dates = this._dataService.monthsBetweenDates('2019-01-01', moment().format('YYYY-MM') + '-01');

        this._dataService.mediaDataBetweenDates(this.dates).subscribe({
          next: (mediaData: IMediaData[][]) => {
            const data: IMediaData[][] = mediaData.filter(array => array.length > 0);
            this.dataMedia = data;
            this.mediaData();
          },
          error: error => {
            console.error(error);
          }
        });
        this._dataService.modelEstimation(this.appComponent.getDate(1)).subscribe({
          next: (modelEstimationData: IModelEstimation[]) => {
            const data: IModelEstimation[] = modelEstimationData;
            this.dataRadar = data;
            this.countryRadarChart = this.countriesDropdown(this.selectedCountry.name)[0];

            this.radarChart();
          },
          error: error => {
            console.error(error);
          }
        });
        resolve(undefined);
      });
    })
  }

  user = {
    user: {
      email: '',
      username: ''
    },
    plan: ''
  };

  public async currentUser() {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('currentUser');
      this._dataService.me(token).subscribe({
        next: user => {
          this.user = user;
          resolve(undefined)
        },
        error: error => {
          reject(error);
        }
      })
    })
  }
  public answers = [];
  showQuestionnaireAnswers(token) {
    this._dataService.showQuestionnaire(this.year + '-0' + String(this.monthNumber + 1), token).subscribe({
      next: (res: IAnswer[]) => {
        if (Array.isArray(res)) {
          this.answers = res.filter(
            res => res.country == this.selectedCountry.code);
        }
        if (this.answers[26] !== undefined) {
          localStorage.setItem('comment', this.answers[26].user_answer)
          localStorage.setItem('commentDate', this.answers[26].date)
        }
        for (let i = 0; i < this.answers.length; i++) {
          if (this.answers[i].question.type === '1') {
            this.answers[i].user_answer = Number(this.answers[i].user_answer);
          }
        }
      }, error: error => {
        console.log(error);
      }
    })
  }

  async ngOnInit(): Promise<void> {
    await this.getData();

    this.selectedCountry.code = (localStorage.getItem('countryCode'))
    this.selectedCountry.name = (localStorage.getItem('countryName'))

    await this.currentUser();
    if (this.user.plan === 'Basic') {
      this.charts.get(4).chart.options.tooltips.enabled = true;
      this.charts.get(4).chart.options.tooltips.custom = null;
      this.charts.get(4).update();
    }

    this.showQuestionnaireAnswers(localStorage.getItem('currentUser'))
  }

  ngAfterViewInit(): void {
    // Linear gradient za overall assesment chart (vrednosti 0, 10)
    const gradient = this.canvas.nativeElement.getContext('2d').createLinearGradient(0, 0, 0, 318);
    gradient.addColorStop(0, 'rgba(67, 126, 225, 0.1)');
    gradient.addColorStop(1, 'rgba(67, 126, 225, 0)');

    const gradient2 = this.canvas.nativeElement.getContext('2d').createLinearGradient(0, 0, 0, 318);
    gradient2.addColorStop(0, 'rgba(137, 67, 225, 0.1)');
    gradient2.addColorStop(1, 'rgba(137, 67, 225, 0)');
    this.mainChartColours = [
      {
        backgroundColor: gradient
      },
      {
        backgroundColor: gradient2
      }
    ];
    // Linear gradient za benchmark chart, (vrednosti -100, 100)
    const gradient3 = this.canvas.nativeElement.getContext('2d').createLinearGradient(0, 0, 0, 527);
    gradient3.addColorStop(0, 'rgba(67, 126, 225, 0.1)');
    gradient3.addColorStop(1, 'rgba(67, 126, 225, 0)');

    const gradient4 = this.canvas.nativeElement.getContext('2d').createLinearGradient(0, 0, 0, 527);
    gradient4.addColorStop(0, 'rgba(137, 67, 225, 0.1)');
    gradient4.addColorStop(1, 'rgba(137, 67, 225, 0)');
    this.benchmarkChartColours = [
      {
        backgroundColor: gradient3
      },
      {
        backgroundColor: gradient4
      }
    ]
  }
}


