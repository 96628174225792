import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { IconSetService } from '@coreui/icons-angular';
import { freeSet } from '@coreui/icons';
import { DataService } from './services/data.service';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { DashboardModule } from './views/dashboard/dashboard.module';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet></router-outlet>',
  providers: [IconSetService],
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    public iconSet: IconSetService,
    private _dataService: DataService,
    //public dashboardComponent: DashboardComponent,
    //public dashboardModule: DashboardModule,
  ) {
    // iconSet singleton
    iconSet.icons = { ...freeSet };

  }

  public getDate(difference: number) {
    const differenceTmp = new Date();
    differenceTmp.setDate(1);
    differenceTmp.setMonth(differenceTmp.getMonth() - difference);
    const month = differenceTmp.getMonth();
    const year = differenceTmp.getFullYear();

    return year + '-' + String(month + 1).padStart(2, '0') + '-01';
  }

  public getMonth(difference: number) {
    const differenceTmp = new Date();
    differenceTmp.setDate(1);
    differenceTmp.setMonth(differenceTmp.getMonth() - difference);
    const month = differenceTmp.getMonth();
    const year = differenceTmp.getFullYear();

    return this._dataService.getMonths()[month] + " " + year;
  }

  public convertMonth(month: string) {
    return Number(this._dataService.getMonths().indexOf(month.split(" ")[0]));
  }
  //public mainChartColours: Array<any> = this.dashboardModule

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
