import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(public _dataService: DataService) { }
  user = {
    user: {
      username: '',
      email: ''
    }
  };

  public currentUser() {
    const token = localStorage.getItem('currentUser');
    this._dataService.me(token).subscribe({
      next: user => {
        this.user = user;
      },
      error: error => {
        console.log(error);
      }
    })
  }

  ngOnInit(): void {
    this.currentUser();
  }
}
