import { Component } from '@angular/core';
import { DataService } from '../../services/data.service';
import { FormGroup, FormControl, FormBuilder, FormArray, AbstractControl, Validators } from '@angular/forms';
import Validation from '../../utils/validation';
import { LoginService } from '../../services/login.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent {
  form: FormGroup;
  submitted = false;

  constructor(public _dataService: DataService,
    public _loginService: LoginService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {

    this.form = this.formBuilder.group(
      {
        username: ['', [Validators.required]],
        password: ['', [Validators.required]],
      }
    );
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    //console.log(JSON.stringify(this.form.value, null, 2));
    this.user.username = this.form.value.username;
    this.user.password = this.form.value.password;

    this._loginService.login(this.user).subscribe(response => {
      this.route.queryParams.subscribe(
        params => {
          this.router.navigate([params.returnUrl || '']);
        });
    },
      error => {
        this.form.controls['username'].setErrors({ invalid: true });
        this.form.controls['password'].setErrors({ invalid: true });
      }
    )

  }

  onReset(): void {
    this.submitted = false;
    this.form.reset();
  }

  user = {
    username: '',
    password: ''
  };
}
