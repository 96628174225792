import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ICustomUser, IUser } from '../interfaces/user';
import { map } from 'rxjs/operators';

const options2 = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'token f896577ca11548ad7cf6bb40db4865a97604b592'
    })
}

@Injectable({
    providedIn: 'root'
})

export class LoginService {
    constructor(private httpClient: HttpClient) { }
    _url: string = 'http://eam.backend.dev.mortar.tovarnaidej.com/api/v1/';

    login(user): Observable<any> {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json'
        })

        return this.httpClient.post<any>(
            this._url + 'auth/', user,
            { headers }).pipe(map(data => {
                if (data) {
                    localStorage.setItem('currentUser', data.token);
                }
                return data;
            }));
    }

    logout() {
        localStorage.removeItem('currentUser');
    }

    register(customUser): Observable<any> {
        return this.httpClient.post<ICustomUser>(this._url + 'profile/', customUser, options2);
    }

}
