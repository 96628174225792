import { Component } from '@angular/core';
import { DataService } from '../../services/data.service';
import { FormGroup, FormControl, FormBuilder, FormArray, AbstractControl, Validators } from '@angular/forms';
import Validation from '../../utils/validation';
import { LoginService } from '../../services/login.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'register.component.html'
})
export class RegisterComponent {
  form: FormGroup;
  submitted = false;

  constructor(public _dataService: DataService,
    public _loginService: LoginService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit(): void {

    this.form = this.formBuilder.group(
      {
        username: ['',
          [
            Validators.required,
            Validators.minLength(1),
            Validators.maxLength(20)
          ]
        ],
        email: ['',
          [Validators.required,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,5}$')
          ]
        ],
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(40)
          ]
        ],
        confirmPassword: ['', Validators.required]
      },
      {
        validators: [Validation.match('password', 'confirmPassword')]
      }
    );
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.user.username = this.form.value.username;
    this.user.email = this.form.value.email;
    this.user.password = this.form.value.password;
    this.register(this.customUser);

  }

  onReset(): void {
    this.submitted = false;
    this.form.reset();
  }

  register(customUser) {
    this._loginService.register(customUser).subscribe(user => {
      this.route.queryParams.subscribe (
        params => {
          this.router.navigate(['login']);
        });
    });
  }

  user = {
    email: '',
    username: '',
    password: ''
  };

  customUser = {
    user: this.user,
    plan: 'Basic'
  };
  
  //{
  //  "user": {
  //    "email": "naove@example.com",
  //    "username": "noveuser",
  //    "password": "noveuser"
  //  },
  //  "plan": "Basic bitch user"
  //}
}
