import { Component, OnInit, ViewChild, ViewChildren, ElementRef, QueryList } from '@angular/core';
import { getStyle, hexToRgba } from '@coreui/coreui/dist/js/coreui-utilities';
import { Chart } from 'chart.js';
import { DataService } from '../../services/data.service';
import { BaseChartDirective } from 'ng2-charts';
import { AppComponent } from '../../app.component';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { DashboardModule } from '../dashboard/dashboard.module';
import { IMacroData, IModelEstimation, IForecast, IQuestion, IAnswer } from '../../interfaces/dashboard';
import * as moment from 'moment';
import { FormGroup, FormControl, FormBuilder, FormArray } from '@angular/forms';




import { map, switchMap, tap, mergeMap } from 'rxjs/operators'
import { ICustomUser } from '../../interfaces/user';
import { LoginService } from '../../services/login.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-analytic',
  templateUrl: './analytic.component.html',
  styleUrls: ['./analytic.component.scss']
})
export class AnalyticComponent implements OnInit {

  @ViewChild('canvas', { static: false }) canvas: ElementRef;
  @ViewChildren(BaseChartDirective) charts: QueryList<BaseChartDirective>
  public questions: IQuestion[];
  form: FormGroup;

  constructor(public _dataService: DataService,
    public appComponent: AppComponent,
    public dashboardComponent: DashboardComponent,
    private FB: FormBuilder,
    public _loginService: LoginService) { }

  public month = this.appComponent.getMonth(0);
  public year = this.month.split(" ")[1];
  public monthNumber = this.appComponent.convertMonth(this.month.split(" ")[0]);

  public country = { name: 'Slovenia', code: 'SLO' };
  public selectedCountry = { name: '', code: '' };
  public countryMainChart = { name: '', code: '' };

  public checked = undefined;
  public checkedComparingCountry = undefined;
  public checkedMonth = undefined;;

  public mainData = [];
  public dataAll = [];
  public data = [];

  public modelEstimationData = [];
  public politicsData = [];
  public securitySituationData = [];
  public investmentBarriersData = [];
  public socialEconomicConditionsData = [];
  public ruleOfLawData = [];

  public forecastLabels = [];
  public forecastPoints = [];
  public forecastData = [];
  public low80Data = [];
  public high80Data = [];
  public low95Data = [];
  public high95Data = [];

  public modelEstimationDates = [];

  public macroData = [];

  public macroDataByCountry = []
  public macroDataIndicators = [];
  public latestMacroData = new Map();

  public mainChartElements = 0;
  public mainChartData1: Array<number> = [];
  public mainChartData2: Array<number> = [];
  public mainChartData: Array<any> = [
    {
      data: this.mainChartData1,
      label: this.selectedCountry.name,

      pointBorderColor: '#07122E',
      fill: true,
    },
    {
      data: this.mainChartData2,
      label: this.countryMainChart.name,
      fill: true,
      pointBorderColor: '#07122E'
    },
    {
      data: this.forecastData,
      fill: false,
      label: 'Forecast',
      pointBorderColor: '#000000',
      color: '#000000',
      borderColor: '#000000',
      backgroundColor: '#000000',
      pointHoverBackgroundColor: '#FFF',
      pointHoverBorderColor: '#000000'
    },
    {
      data: this.low80Data,
      fill: '+1',
      label: 'Low 80',
      color: '#7f00ff',
      backgroundColor: '#7f00ff',
      pointBorderColor: '#7f00ff',
      borderColor: '#7f00ff',
      pointHoverBackgroundColor: '#FFF',
      pointHoverBorderColor: '#7f00ff'
    },
    {
      data: this.high80Data,
      fill: '0',
      label: 'High 80',
      color: '#7f00ff',
      backgroundColor: '#7f00ff',
      pointBorderColor: '#7f00ff',
      borderColor: '#7f00ff',
      pointHoverBackgroundColor: '#FFF',
      pointHoverBorderColor: '#7f00ff'
    },
    {
      data: this.low95Data,
      fill: '0',
      label: 'Low 95',
      color: '#CF9FFF',
      backgroundColor: '#CF9FFF',
      pointBorderColor: '#CF9FFF',
      borderColor: '#CF9FFF',
      pointHoverBackgroundColor: '#FFF',
      pointHoverBorderColor: '#CF9FFF'
    },
    {
      data: this.high95Data,
      fill: '-1',
      label: 'High 95',
      color: '#CF9FFF',
      backgroundColor: '#CF9FFF',
      pointBorderColor: '#CF9FFF',
      borderColor: '#CF9FFF',
      pointHoverBackgroundColor: '#FFF',
      pointHoverBorderColor: '#CF9FFF'
    },
    {
      data: this.politicsData,
      label: 'Politics',
      hidden: false,
      fill: false,
      pointBackgroundColor: 'transparent',
      backgroundColor: 'transparent',
      pointBorderColor: 'transparent',
      borderColor: 'transparent',
      pointHoverBackgroundColor: 'transparent',
      pointHoverBorderColor: 'transparent',
      pointRadius: 0,
      borderWidth: 0
    },
    {
      data: this.securitySituationData,
      label: 'Security situation',
      hidden: false,
      fill: false,
      pointBackgroundColor: 'transparent',
      backgroundColor: 'transparent',
      pointBorderColor: 'transparent',
      borderColor: 'transparent',
      pointHoverBackgroundColor: 'transparent',
      pointHoverBorderColor: 'transparent',
      pointRadius: 0,
      borderWidth: 0
    },
    {
      data: this.investmentBarriersData,
      label: 'Investment Barriers',
      hidden: false,
      fill: false,
      pointBackgroundColor: 'transparent',
      backgroundColor: 'transparent',
      pointBorderColor: 'transparent',
      borderColor: 'transparent',
      pointHoverBackgroundColor: 'transparent',
      pointHoverBorderColor: 'transparent',
      pointRadius: 0,
      borderWidth: 0
    },
    {
      data: this.socialEconomicConditionsData,
      label: 'Social Economic Conditions',
      hidden: false,
      fill: false,
      pointBackgroundColor: 'transparent',
      backgroundColor: 'transparent',
      pointBorderColor: 'transparent',
      borderColor: 'transparent',
      pointHoverBackgroundColor: 'transparent',
      pointHoverBorderColor: 'transparent',
      pointRadius: 0,
      borderWidth: 0
    },
    {
      data: this.ruleOfLawData,
      label: 'Rule of Law',
      hidden: false,
      fill: false,
      pointBackgroundColor: 'transparent',
      backgroundColor: 'transparent',
      pointBorderColor: 'transparent',
      borderColor: 'transparent',
      pointHoverBackgroundColor: 'transparent',
      pointHoverBorderColor: 'transparent',
      pointRadius: 0,
      borderWidth: 0
    }
  ];

  public mainChartLabels: Array<any> = [];

  public mainChartOptions: any = {
    tooltips: {
      enabled: true,
      intersect: true,
      position: 'nearest',
      mode: 'index',
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [{

        offset: false,
        gridLines: {
          drawOnChartArea: true,
          //offsetGridLines: true
          offsetGridLines: false,
          drawBorder: false,
          drawTicks: true,
          tickMarkLength: 1
          // zeroLineBorderDashOffset: 1.0
        },
        ticks: {
          callback: function (value: any) {
            return value
          },
          fontFamily: "'DM Sans', 'sans-serif",
          fontStyle: 'bold',
          fontWeight: '700',
          fontSize: 12,
          fontColor: hexToRgba('#BBBDC2'),
          lineHeight: 1.98,
          beginAtZero: false,
          autoSkip: true,
          padding: 10,
          labelOffset: -10,
          // autoSkipPadding: 0, padding: 20
        }
      }],
      layout: {
        padding: {
          left: -110,
          right: -10,
          top: 0,
          bottom: 0
        }
      },
      yAxes: [{
        gridLines: {
          drawOnChartArea: true,
          //offsetGridLines: true
          offsetGridLines: false,
          drawBorder: false,
          drawTicks: true,
          tickMarkLength: 1
          // zeroLineBorderDashOffset: 1.0
        },
        ticks: {
          beginAtZero: true,
          stepSize: 2,
          fontFamily: "'DM Sans', 'sans-serif",
          fontStyle: 'bold',
          fontWeight: '700',
          fontSize: 12,
          fontColor: hexToRgba('#BBBDC2'),
          lineHeight: 1.98,
          autoSkip: true,
          padding: 20
          // max: 10
        }
      }]
    },
    elements: {
      line: {
        borderWidth: 2,
        tension: 0.00001
      },
      point: {
        radius: 0,
        hitRadius: 20,
        hoverRadius: 4,
        hoverBorderWidth: 3,
      }
    },
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        padding: 28,
        boxWidth: 12,
        fontFamily: "'DM Sans', sans-serif",
        fontStyle: 'normal',
        fontWeight: '500',
        fontColor: hexToRgba('#07122E'),
        fontSize: 12,
        generateLabels: function (chart) {
          let labels = Chart.defaults.global.legend.labels.generateLabels(chart);
          labels[0].fillStyle = 'rgba(67, 126, 225, 1)';
          labels[0].strokeStyle = 'rgba(67, 126, 225, 1)';
          labels[1].fillStyle = 'rgba(137, 67, 225, 1)';
          labels[1].strokeStyle = 'rgba(137, 67, 225, 1)';
          return labels;
        },
        filter: function (item, chart) {
          // odstrani legend boxe
          return !item.text.includes('Forecast')
            && !item.text.includes('Politics')
            && !item.text.includes('Security situation')
            && !item.text.includes('Investment Barriers')
            && !item.text.includes('Social Economic Conditions')
            && !item.text.includes('Rule of Law')
            && !item.text.includes('Low 80')
            && !item.text.includes('High 80')
            && !item.text.includes('Low 95')
            && !item.text.includes('High 95');
        }
      }
    },
    options: {
      hover: {
        mode: 'y',
        intersect: 'false',
        axis: 'y'
      }
    }
  };

  public mainChartColours: Array<any> = [
    { // brandInfo
      borderColor: 'rgba(67, 126, 225, 0.8)',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderWidth: 5
    },
    { // brandSuccess
      borderColor: 'rgba(137, 67, 225, 0.6)',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderWidth: 5
    }
  ];

  public mainChartLegend = true;
  public mainChartType = 'line';

  public countriesDropdown(countryName: string) {
    return this.dashboardComponent.countriesDropdown(countryName);
  }

  public selectCountryMainChart(country) {
    if (this.checkedComparingCountry === country.code) {
      this.checkedComparingCountry = undefined;
      this.mainChartData[1].label = [];
      this.charts.get(0).chart.data.datasets[1].data = [];

      this.charts.get(0).update();
      return;
    }

    this.countryMainChart = country;
    this.checkedComparingCountry = country.code;
    this.mainChartData[1].label = country.name;
    this.charts.get(0).update();

    this.modelEstimation();
  }

  public selectCountry(country) {
    this.selectedCountry = country;
    this.country = country;
    this.checked = undefined;
    this.checkedMonth = undefined;

    localStorage.setItem('countryCode', this.selectedCountry.code);
    localStorage.setItem('countryName', this.selectedCountry.name);
    const token = localStorage.getItem('currentUser');
    this.showQuestionnaireAnswers(token);

    this.modelEstimation();
  }

  public getMonth(difference: number) {
    const differenceTmp = new Date();
    differenceTmp.setDate(1);
    differenceTmp.setMonth(differenceTmp.getMonth() - difference);
    const month = differenceTmp.getMonth();
    const year = differenceTmp.getFullYear();

    return this._dataService.getMonths()[month] + " " + year;
  }

  public async selectMonth(month: number) {
    let oldChecked = this.checkedMonth;
    this.checkedMonth = month;
    this.month = this.getMonth(month);
    this.monthNumber = this.appComponent.convertMonth(this.month.split(" ")[0]);
    this.year = this.month.split(" ")[1];
    const token = localStorage.getItem('currentUser');
    this.showQuestionnaireAnswers(token);

    if (month === 0 && oldChecked !== month) {
      await this.forecast();
      this.mainChartLabels = this.forecastLabels;

      this.mainChartElements = this.data.length;
      const forecastDataTmp = new Array(this.dataAll.length - 1).fill(undefined);
      const low80DataTmp = new Array(this.dataAll.length - 1).fill(undefined);
      const high80DataTmp = new Array(this.dataAll.length - 1).fill(undefined);
      const low95DataTmp = new Array(this.dataAll.length - 1).fill(undefined);
      const high95DataTmp = new Array(this.dataAll.length - 1).fill(undefined);

      const mainChartDataTmp = new Array(this.forecastData.length).fill(undefined);
      this.forecastData.unshift(this.mainData[this.mainData.length - 1])
      this.low80Data.unshift(this.mainData[this.mainData.length - 1])
      this.high80Data.unshift(this.mainData[this.mainData.length - 1])
      this.low95Data.unshift(this.mainData[this.mainData.length - 1])
      this.high95Data.unshift(this.mainData[this.mainData.length - 1])

      const FinalArr = [...this.mainData, ...mainChartDataTmp];
      this.charts.get(0).chart.data.datasets[0].data = FinalArr;

      this.forecastData = [...forecastDataTmp, ...this.forecastData];
      this.low80Data = [...low80DataTmp, ...this.low80Data];
      this.high80Data = [...high80DataTmp, ...this.high80Data];
      this.low95Data = [...low95DataTmp, ...this.low95Data];
      this.high95Data = [...high95DataTmp, ...this.high95Data];

      this.charts.get(0).chart.data.datasets[2].data = this.forecastData;
      this.charts.get(0).chart.data.datasets[3].data = this.low80Data;
      this.charts.get(0).chart.data.datasets[4].data = this.high80Data;
      this.charts.get(0).chart.data.datasets[5].data = this.low95Data;
      this.charts.get(0).chart.data.datasets[6].data = this.high95Data;
      this.charts.get(0).chart.data.datasets[7].data = this.politicsData;
      this.charts.get(0).chart.data.datasets[8].data = this.securitySituationData;
      this.charts.get(0).update();
      return;
    }
    if (oldChecked === month) {
      this.month = this.getMonth(0);
      this.monthNumber = this.appComponent.convertMonth(this.month.split(" ")[0]);

      this.year = this.month.split(" ")[1];

      this.checkedMonth = undefined;
      await this.getData();
    }

    await this.getData();
  }

  public modelEstimation() {
    const country = this.data.map(value =>
      value.filter(element =>
        element.Country === localStorage.getItem('countryCode'))
    );

    this.modelEstimationData = country.map(value =>
      value.filter(element =>
        element.Category === 'Overall_assessment')[0]
    ).map(value =>
      value.Model_estimation
    );

    this.politicsData = country.map(value =>
      value.filter(element =>
        element.Category === 'Politics')[0]
    ).map(value =>
      value.Model_estimation
    );

    this.securitySituationData = country.map(value =>
      value.filter(element =>
        element.Category === 'Security_situation')[0]
    ).map(value =>
      value.Model_estimation
    );

    this.ruleOfLawData = country.map(value =>
      value.filter(element =>
        element.Category === 'Rule_of_law')[0]
    ).map(value =>
      value.Model_estimation
    );

    this.investmentBarriersData = country.map(value =>
      value.filter(element =>
        element.Category === 'Investment_barriers')[0]
    ).map(value =>
      value.Model_estimation
    );

    this.socialEconomicConditionsData = country.map(value =>
      value.filter(element =>
        element.Category === 'Social_economic_conditions')[0]
    ).map(value =>
      value.Model_estimation
    );

    this.mainChartElements = country.length;
    this.mainData = this.modelEstimationData;
    this.mainChartData1 = this.modelEstimationData;

    this.charts.get(0).chart.data.datasets[0].data = this.mainChartData1;
    this.charts.get(0).chart.data.datasets[7].data = this.politicsData;
    this.charts.get(0).chart.data.datasets[8].data = this.securitySituationData;
    this.charts.get(0).chart.data.datasets[9].data = this.ruleOfLawData;
    this.charts.get(0).chart.data.datasets[10].data = this.investmentBarriersData;
    this.charts.get(0).chart.data.datasets[11].data = this.socialEconomicConditionsData;

    this.charts.get(0).update();

    if (this.countryMainChart.code === '') return;
    if (this.checkedComparingCountry !== undefined && this.checkedComparingCountry === this.selectedCountry.code) {
      this.checkedComparingCountry = this.countriesDropdown(localStorage.getItem('countryCode'));
    }
    this.mainChartData[0].label = this.selectedCountry.name;
    if (this.checkedComparingCountry !== undefined)
      this.countryMainChart = this._dataService.countries.filter(element => element.code === this.checkedComparingCountry)[0];
    if (this.checked !== undefined)
      this.countryMainChart = this._dataService.countries.filter(element => element.code === this.checked)[0];

    this.mainChartData[1].label = this.countryMainChart.name;

    const comparingCountry = this.data.map(value =>
      value.filter(element =>
        element.Country === this.countryMainChart.code));

    const comparingOverallAssessment = comparingCountry.map(value =>
      value.filter(element =>
        element.Category === 'Overall_assessment')[0]);

    const comparingOverallAssessmentValue = comparingOverallAssessment.map(value =>
      value.Model_estimation);

    if (this.forecastData.length === 0) {
      let overallAssesmentDates = comparingCountry.map(e => e.map(i => i.Date));
      let formattedOverallAssesmentDates = overallAssesmentDates.flat().map(date => moment(date, 'YYYY-MM-DD').format('MMM-YYYY'));
      formattedOverallAssesmentDates.map(element => this.mainChartLabels.push(element))

      this.mainChartLabels = [...new Set(formattedOverallAssesmentDates)];

      this.mainChartData1 = this.modelEstimationData;
      this.mainChartData2 = comparingOverallAssessmentValue;

      this.charts.get(0).chart.data.datasets[0].data = this.mainChartData1;
      this.charts.get(0).chart.data.datasets[1].data = this.mainChartData2;
      this.charts.get(0).update();
    }

    else {
      this.mainChartData1 = this.modelEstimationData;
      this.mainChartData2 = comparingOverallAssessmentValue;

      this.mainChartElements = this.data.length;

      const mainChartDataTmp = new Array(this.forecastData.length).fill(undefined);
      const mainChartData2Tmp = new Array(this.forecastData.length).fill(undefined);

      const FinalArr = [...this.mainData, ...mainChartDataTmp];
      this.charts.get(0).chart.data.datasets[0].data = FinalArr;
      this.charts.get(0).update();
    }
  }

  public async forecast() {
    return new Promise((resolve, reject) => {
      this._dataService.forecast(this.country.code).subscribe({
        next: data => {
          const forecast: IForecast[] = data as IForecast[];
          const lastDate = forecast[forecast.length - 1].Date;
          const latestDate = forecast[0].Date;
          const newDate = moment(latestDate).subtract(1, 'month').format('YYYY-MM');

          const dates = this._dataService.monthsBetweenDates('2021-01-01', lastDate);
          const mainChartDates = this._dataService.monthsBetweenDates('2021-01-01', String(newDate) + '-01');

          const overallAssesmentLabels = dates.map(value =>
            moment(value, 'YYYY-MM-DD').format('MMM-YYYY')
          )

          const overallAssesmentLabels2 = mainChartDates.map(value =>
            moment(value, 'YYYY-MM-DD').format('MMM-YYYY')
          )
          this.forecastLabels = overallAssesmentLabels;
          this.mainChartLabels = overallAssesmentLabels2;

          this.forecastPoints = forecast.map(element =>
            element.Point_Forecast);

          this.forecastData = forecast.map(element =>
            element.Point_Forecast);

          this.low80Data = forecast.map(element =>
            element.Lo_80);

          this.high80Data = forecast.map(element =>
            element.Hi_80);

          this.low95Data = forecast.map(element =>
            element.Lo_95);

          this.high95Data = forecast.map(element =>
            element.Hi_95);

          resolve(undefined);
        },
        error: error => {
          reject(error);
        }

      })
    })
  }

  public async getData() {
    return new Promise((resolve, reject) => {

      this.modelEstimationDates = this._dataService.monthsBetweenDates('2019-01-01', String(this.year) + '-' + String(this.monthNumber + 1).padStart(2, "0") + '-01');
      this._dataService.modelEstimationBetweenDates(this.modelEstimationDates).subscribe({
        next: (element: IModelEstimation[][]) => {
          element = element.filter(array => array.length > 0);
          this.data = element;
          this.dataAll = element;

          let filteredArray: IModelEstimation[][] = element.map(e => e.filter(i =>
            i.Country === this.selectedCountry.code &&
            i.Category === 'Overall_assessment'))

          this.forecastData = [];
          this.low80Data = [];
          this.high80Data = [];
          this.low95Data = [];
          this.high95Data = [];

          this.charts.get(0).chart.data.datasets[2].data = [];
          this.charts.get(0).chart.data.datasets[3].data = [];
          this.charts.get(0).chart.data.datasets[4].data = [];
          this.charts.get(0).chart.data.datasets[5].data = [];
          this.charts.get(0).chart.data.datasets[6].data = [];
          this.charts.get(0).update();

          let overallAssesmentDates = filteredArray.map(e => e.map(i => i.Date));
          let formattedOverallAssesmentDates = overallAssesmentDates.flat().map(date => moment(date, 'YYYY-MM-DD').format('MMM-YYYY'));

          this.mainChartLabels = formattedOverallAssesmentDates

          this.modelEstimation();

          resolve(undefined);
        },
        error: error => {
          reject(error);
        }
      });

    })
  }

  public updateAnswer(id, body, token) {
    this._dataService.updateAnswer(id, body, token).subscribe(() => {
    })
  }

  onSubmit() {
    const token = localStorage.getItem('currentUser')
    const formArray: FormArray = this.form.get('array') as FormArray;

    formArray.value.forEach((formGroup: FormGroup, i: number) => {
      this.values[i].date = new Date(this.year + '-0' + String(this.monthNumber + 1)).toISOString(),
        this.values[i].country = this.selectedCountry.code,
        this.values[i].user = token,
        this.values[i].question = this.questions[i].id,
        this.values[i].user_answer = formGroup['user_answer']
    });

    if (this.answers.length === 0) {
      this._dataService.createQuestionnaire(this.values, token).subscribe();
    } else {
      this.answers.forEach((answer, i) => {
        let body = {
          user_answer: this.values[i].user_answer
        }
        this.updateAnswer(this.answers[i].id, body, token);
      });
      this._dataService.showQuestionnaire(this.year + '-0' + String(this.monthNumber + 1), token).subscribe(res => {
      })
    }
  }

  questionsList(token) {
    this._dataService.questionsList(token).subscribe({
      next: data => {
        this.questions = data
      },
      error: error => {
        console.log(error)
      }
    });
  }

  showQuestionnaireAnswers(token) {
    this._dataService.showQuestionnaire(this.year + '-0' + String(this.monthNumber + 1), token).subscribe({
      next: (res: IAnswer[]) => {
        if (Array.isArray(res)) {
          this.answers = res.filter(
            res => res.country == this.selectedCountry.code);
        }

        const control = <FormArray>this.form.controls['array'];

        if (this.answers.length === 0) {
          for (let i = 0; i < control.controls.length; i++) {
            control.controls[`${i}`].patchValue({ 'user_answer': 0 })
            if (i === 25 || i === 26) {
              control.controls[`${i}`].patchValue({ 'user_answer': '' })
            }
          }
        }

        for (let i = 0; i < this.answers.length; i++) {
          if (this.answers[i].question.type === '1') {
            this.answers[i].user_answer = Number(this.answers[i].user_answer);
            control.controls[`${i}`].patchValue({ 'user_answer': this.answers[i].user_answer })
          }
          control.controls[`${i}`].patchValue({ 'user_answer': this.answers[i].user_answer })
        }
      }, error: error => {
        console.log(error);
      }
    })
  }

  values = new Array<IAnswer>(27).fill(undefined).map((x) => {
    return {
      date: null,
      country: null,
      user: null,
      question: null,
      user_answer: null
    }
  }
  )

  answersScale = [
    {
      min: '1 = zelo nestabilno',
      max: '4 = zelo stabilno'
    },
    {
      min: '1 = zelo slaba',
      max: '4 = zelo dobra'
    },
    {
      min: '1 = zelo majhen',
      max: '4 = zelo velik'
    },
    {
      min: '1 = ni ogrožena',
      max: '4 = zelo ogrožena'
    },
    {
      min: '1 = zelo majhen',
      max: '4 = zelo velik'
    },
    {
      min: '1 = gospodarstvo je neodvisno',
      max: '4 = politika je zelo vpeta'
    },
    {
      min: '1 = korupcija ne nezaznavna',
      max: '4 = korupcija je pomembno gonilo dogajanja'
    },
    {
      min: '1 = zelo majhna verjetnost',
      max: '4 = zelo velika verjetnost'
    },
    {
      min: '1 = neodvistnost je zelo majhna',
      max: '4 = zelo je velika'
    },
    {
      min: '1 = zelo majhna verjetnost',
      max: '4 = zelo velika verjetnost'
    },
    {
      min: '1 = zelo slabo',
      max: '4 = zelo dobro'
    },
    {
      min: '1 = podpora je zelo majhna',
      max: '4 = zelo velika'
    },
    {
      min: '1 = zelo majhna verjetnost',
      max: '4 = zelo velika verjetnost'
    },
    {
      min: '1 = zelo lahko',
      max: '4 = zelo težko'
    },
    {
      min: '1 = zelo majhna verjetnost',
      max: '4 = zelo velika verjetnost'
    },
    {
      min: '1 = zelo majhna verjetnost',
      max: '4 = zelo velika verjetnost'
    },
    {
      min: '1 = zelo slaba',
      max: '4 = zelo dobra'
    },
    {
      min: '1 = zelo slaba',
      max: '4 = zelo dobra'
    },
    {
      min: '1 = zelo majhna verjetnost',
      max: '4 = zelo velika verjetnost'
    },
    {
      min: '1 = zelo majhna verjetnost',
      max: '4 = zelo velika verjetnost'
    },
    {
      min: '1 = zelo majhna verjetnost',
      max: '4 = zelo velika verjetnost'
    },
    {
      min: '1 = zelo malo pristona',
      max: '4 = zelo so prisotna'
    },
    {
      min: '1 = zelo majhna verjetnost',
      max: '4 = zelo velika verjetnost'
    },
    {
      min: '1 = zelo majhna verjetnost',
      max: '4 = zelo velika verjetnost'
    },
    {
      min: '1 = zelo majhna verjetnost',
      max: '4 = zelo velika verjetnost'
    },
    {
      min: '',
      max: ''
    }
  ]
  public answers = [];

  public questionnaireByDate;

  ngOnInit(): void {
    const token = localStorage.getItem('currentUser');

    if (localStorage.getItem('countryCode') === "undefined" || this.selectedCountry === undefined) {
      this.selectedCountry.code = JSON.stringify(localStorage.setItem('countryCode', 'SLO'));
      this.selectedCountry.name = JSON.stringify(localStorage.setItem('countryName', 'Slovenia'));
      localStorage.setItem('countryCode', this._dataService.countries[0].code)
      localStorage.setItem('countryName', this._dataService.countries[0].name)
      this.selectCountry(this.selectedCountry.code);
    }
    this.selectedCountry.code = (localStorage.getItem('countryCode'))
    this.selectedCountry.name = (localStorage.getItem('countryName'))

    this.questionsList(token);
    this.showQuestionnaireAnswers(token);

    this.form = this.FB.group({
      array: this.FB.array(
        this.values.map(x => this.FB.group({
          question: this.FB.control(x.question),
          user_answer: this.FB.control(x.user_answer)
        }))
      )
    });
  }

  async ngAfterViewInit(): Promise<void> {
    await this.getData();
    const gradient = this.canvas.nativeElement.getContext('2d').createLinearGradient(0, 0, 0, 170);
    gradient.addColorStop(0, 'rgba(67, 126, 225, 0.1)');
    gradient.addColorStop(1, 'rgba(67, 126, 225, 0)');

    const gradient2 = this.canvas.nativeElement.getContext('2d').createLinearGradient(0, 0, 0, 170);
    gradient2.addColorStop(0, 'rgba(137, 67, 225, 0.1)');
    gradient2.addColorStop(1, 'rgba(137, 67, 225, 0)');
    this.mainChartColours = [
      {
        backgroundColor: gradient
      },
      {
        backgroundColor: gradient2
      }
    ];
  }
}