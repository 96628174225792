import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, forkJoin } from 'rxjs';
import { IMediaData, IMacroData, IModelEstimation, IForecast, IQuestion, IAnswer } from '../interfaces/dashboard';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { IUser, ICustomUser } from '../interfaces/user';

const options = {
  params: {
    api_key: '8ce60xero1zxn9zxm97y6vu0h6b4tum5lv3f8my8j3mi2v19v2mau5'
  },
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'text/plain'
  },
  observe: 'response'
}

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private http: HttpClient) { }

  private _dataSource = new Subject<string>();
  data$ = this._dataSource.asObservable();

  _url: string = 'http://46.101.73.238/api/';
  _eamUrl: string = 'http://eam.backend.dev.mortar.tovarnaidej.com/api/v1/';

  token: string = '';

  mediaData(date: string): Observable<IMediaData[]> {
    const tmpOptions = {
      params: {
        api_key: options.params.api_key,
        date: date
      },
      headers: options.headers
    }
    return this.http.get<IMediaData[]>(this._url + 'media_data', tmpOptions);
  }

  macroData(date: string): Observable<IMacroData[]> {
    const tmpOptions = {
      params: {
        api_key: options.params.api_key,
        date: date
      },
      headers: options.headers
    }
    return this.http.get<IMacroData[]>(this._url + 'macro_data', tmpOptions);
  }

  modelEstimation(date: string): Observable<IModelEstimation[]> {
    const tmpOptions = {
      params: {
        api_key: options.params.api_key,
        date: date
      },
      headers: options.headers
    }
    return this.http.get<IModelEstimation[]>(this._url + 'model_estimation', tmpOptions);
  }

  forecast(countryCode: string): Observable<IForecast[]> {
    const tmpOptions = {
      params: {
        api_key: options.params.api_key,
        country: countryCode
      },
      headers: options.headers
    }
    return this.http.get<IForecast[]>(this._url + 'forecast', tmpOptions);
  }

  public dates() {
    const months = new Array(12).fill(undefined).map((value, index) => index).map((value) => String(Number(value) + 1).padStart(2, "0"));
    const date = new Date();
    date.setDate(1);
    const year = date.getFullYear();
    date.setFullYear(year - 1);

    return months.map((value) => String(date.getFullYear()) + '-' + value + '-' + String(date.getDay()).padStart(2, "0"));
  }

  //public inputObject = this.dates();

  public mediaDataBetweenDates(inputObject: string[]) {
    let observableBatch = [];

    inputObject.forEach((key) => {
      const tmpOptions = {
        params: {
          api_key: options.params.api_key,
          date: key
        },
        headers: options.headers
      }
      observableBatch.push(this.http.get<IMediaData[]>(this._url + 'media_data', tmpOptions).pipe(
        map(
          (response: any) =>
            response as IMediaData[][]
        )
      ));
    });
    return forkJoin(observableBatch);
  }

  public modelEstimationBetweenDates(inputObject: string[]) {
    let observableBatch = [];

    inputObject.forEach((key) => {
      const tmpOptions = {
        params: {
          api_key: options.params.api_key,
          date: key
        },
        headers: options.headers
      }
      observableBatch.push(this.http.get<IModelEstimation[]>(this._url + 'model_estimation', tmpOptions).pipe(map((response: any) => response as IModelEstimation[][])));
    });
    return forkJoin(observableBatch);
  }

  public macroDataBetweenDates(inputObject: string[]) {
    let observableBatch = [];

    inputObject.forEach((key) => {
      const tmpOptions = {
        params: {
          api_key: options.params.api_key,
          date: key
        },
        headers: options.headers
      }
      observableBatch.push(this.http.get<IMacroData[]>(this._url + 'macro_data', tmpOptions).pipe(map((response: any) => response as IMacroData[][])));
    });
    return forkJoin(observableBatch);
  }

  public monthsBetweenDates(start: string, end: string) {
    const dateStart = moment(start);
    const dateEnd = moment(end);
    const timeValues = [];

    while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
      timeValues.push((dateStart.format('YYYY-MM')) + '-01');
      dateStart.add(1, 'month');
    }
    return timeValues;
  }

  sendData(msg: any) {
    this._dataSource.next(msg);
  }

  questionsList(token): Observable<IQuestion[]> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `token ${token}` });
    return this.http.get<IQuestion[]>(this._eamUrl + 'questions/', { headers: headers });
  }

  answersList(token): Observable<IQuestion[]> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `token ${token}` });
    return this.http.get<IQuestion[]>(this._eamUrl + 'answers/', { headers: headers });
  }

  createQuestionnaire(body, token) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `token ${token}` });
    return this.http.post<IAnswer[]>(this._eamUrl + 'questionnaire/', body, { headers: headers });
  }

  answer(id, token) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `token ${token}` });
    return this.http.get<IAnswer[]>(this._eamUrl + `answers/${id}/`, { headers: headers });
  }

  updateAnswer(id, body, token) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `token ${token}` });
    return this.http.patch<IAnswer>(this._eamUrl + `questionnaire/${id}/`, body, { headers: headers });
  }

  showQuestionnaire(date, token) {
    const options = {
      params: {
        date: date
      },
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `token ${token}`
      })
    }

    return this.http.get<IAnswer[]>(this._eamUrl + 'questionnaire/find-by-date/', /*{ headers: headers }*/options);
  }

  me(token) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `token ${token}` });
    return this.http.get<ICustomUser>(this._eamUrl + 'profile/me/', { headers: headers });
  }

  getMonths() {
    return ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  }

  public countries: Array<any> = [
    {
      name: 'Slovenia',
      code: 'SLO'
    },
    {
      name: 'Croatia',
      code: 'CRO'
    },
    {
      name: 'Bosnia and Herzegovina',
      code: 'BiH'
    },
    {
      name: 'Serbia',
      code: 'SRB'
    },
    {
      name: 'Montenegro',
      code: 'MNE'
    },
    {
      name: 'Macedonia',
      code: 'MKD'
    }];
}


